import React from 'react';
import addProduct from '../redux/actions/addProduct';
import removeProduct from '../redux/actions/removeProduct';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import QuantitySelector from './QuantitySelector';

class AddToCarButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addToCar: (this.props.quantity !== 0),
      quantity: this.props.quantity,
    };
  }
  onHandlerAddToCar() {
    this.setState({ addToCar: true, quantity: 1 });
    this.props.addProduct({ product: this.props.product, currentCompany: this.props.currentCompany });
  }
  onAddProduct(quantity) {
    this.props.addProduct({ product: this.props.product, currentCompany: this.props.currentCompany });
  }
  onRemoveProduct(quantity) {
    if(quantity === 0)
      this.setState({ addToCar: false });
    this.props.removeProduct({ product: this.props.product, currentCompany: this.props.currentCompany });
  }
  render() {
    return (
      <>
        {this.state.addToCar === false &&
          <Button variant="contained" color="primary" aria-label="add to the shopping car" onClick={this.onHandlerAddToCar.bind(this)}>
            <AddShoppingCartIcon /> Escoger
          </Button>
        }
        {this.state.addToCar === true &&
          <QuantitySelector size="small" quantity={this.state.quantity} onAddProduct={this.onAddProduct.bind(this)} onRemoveProduct={this.onRemoveProduct.bind(this)}/>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  currentCompany: state.currentCompany,
});

const mapDispatchToProps = {
  addProduct,
  removeProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddToCarButton);
