import RestaurantDAO from "../../DAO/RestaurantDAO";

export const getRestaurantByAliasType = 'GET_RESTAURANT_BY_ALIAS';

export const getCurrentCompany = (alias, callback = () => {}) => {
  return (dispatch, getState) => {
    RestaurantDAO.getRestaurant(alias, (restaurant) => {
      callback(restaurant);
      dispatch( { type: getRestaurantByAliasType, payload: restaurant } );
    });
  }
}