import { SERVICE_PREFIX } from '../config-front';

const service_prefix = SERVICE_PREFIX;
const PREFIX_ROLE = 'user';

export default class RestaurantDAO {
  static getRestaurantsByLocation(departamentoYMunicipio, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/location-filter/${departamentoYMunicipio}`, {
      method: 'GET',
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static getRestaurant(alias, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/restaurants/${alias}`, {
      method: 'GET',
    })
    .then(res => {
      return res.json();
    })
    .then(data => {
      callback(data);
    });
  }
}