import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '../../routes';
import ProductDetail from './CustomProductDetail';
import { withRouter } from "react-router-dom";
import { Button } from '@material-ui/core';
import showEditProduct from '../../redux/actions/showEditProduct';
import { getCurrentCompany } from '../../redux/actions/getCurrentCompany';
import { isEmptyObject } from '../../utils/utils';

class EditCustomProductScreen extends React.Component {
  constructor(props){
    super(props);
    document.getElementsByTagName('body')[0].className = '';
    this.props.getCurrentCompany(this.props.match.params.companyAlias);
  }
  render() {
    const { productToEditToShow, currentCompany } = this.props;
    return (
      <div className="container">
        {!isEmptyObject(productToEditToShow) &&
          <ProductDetail currentCompany={currentCompany} product={productToEditToShow} buttonText="Guardar" handleProductOperation={(newProduct) => {
            //this.props.editProduct(newProduct);
            window.location.hash = Routes.getShoppingCar(this.props.match.params.companyAlias);
          }}></ProductDetail>
        }
        {isEmptyObject(productToEditToShow) &&
          <>
            <h4>No tienes productos en tu lista</h4>
            <Button variant="contained" color="primary" onClick={() => {
              window.location.hash = Routes.getProductsSelector(this.props.match.params.companyAlias);
            }}>
              Ver mi lista
            </Button>
          </>
        } 
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productsSelected: state.produts,
  productToEditToShow: state.productToEdit,
  currentCompany: state.currentCompany,
});

const mapDispatchToProps = {
  showEditProduct,
  getCurrentCompany,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditCustomProductScreen));
