import RestaurantDAO from '../../DAO/RestaurantDAO';

export const getRestaurantsByLocationType = 'GET_RESTAURANTS_BY_LOCATION';

export const getRestaurantsByLocation = (departamentoYMunicipio) => {
    return (dispatch, getState) => {
        RestaurantDAO.getRestaurantsByLocation(departamentoYMunicipio, (restaurants) => {
          dispatch( { type: getRestaurantsByLocationType, payload: restaurants } );
        });
    }
}

