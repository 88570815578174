import React from 'react';
import { AppBar } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link, Toolbar, IconButton, Badge } from '@material-ui/core';
import logoAlacuenta from '../img/Alacuenta-logo.png';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { BACK_ESTABLECIMIENTOS, CONTACT_PAGE, FAQ,  Routes } from '../routes';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { SERVICE_PREFIX } from '../config-front';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

class AppToolBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      menuEl: null
    };

    this.menuItems = [
      {
        name: '¿Eres un negocio?',
        action: BACK_ESTABLECIMIENTOS,
        type: 'external'
      },
      {
        name: 'FAQ',
        action: this.goToFAQ,
        type: 'internal',
        path: FAQ
      },
      {
        name: 'Contáctanos',
        action: this.goToContactPage,
        type: 'internal',
        path: CONTACT_PAGE
      }
    ];
  }
  handleClick = (event) => {
    this.setState({
      menuEl: event.currentTarget
    });
  }
  handleClose = () => {
    this.setState({
      menuEl: null
    });
  }
  goToFAQ = () => {
    this.handleClose();
    window.location.hash = Routes.getFAQ(this.props.match.params.companyAlias);
  }
  goToContactPage = () => {
    this.handleClose();
    window.location.hash = Routes.getContactPage(this.props.match.params.companyAlias);
  }
  render = () => {
    const { shoppingCarList } = this.props;
    return (
      <AppBar position="sticky" className="app-bar" style={SERVICE_PREFIX.includes("http://localhost") ? { backgroundColor:"#0f0"} : {}}>
        {this.props.isMobileVersion &&
          <Toolbar>
            <IconButton edge="start" className="menuButton" color="inherit" aria-label="menu" onClick={this.handleClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={this.state.menuEl}
              keepMounted
              open={Boolean(this.state.menuEl)}
              onClose={this.handleClose}
            >
            {this.menuItems.map(menuItem => (
              <>
              {menuItem.type === 'external' &&
              <MenuItem key={menuItem.name}>
                <Link href={menuItem.action} target="_blank" className = { this.props.match.path === menuItem.path ? "link-active" : "link-normal" }>
                  {menuItem.name}
                </Link>
              </MenuItem>
              }
              {menuItem.type === 'internal' &&
              <MenuItem key={menuItem.name} onClick={menuItem.action} className = { this.props.match.path === menuItem.path ? "link-active" : "link-normal" }>
                {menuItem.name}
              </MenuItem>
              }
              </>
            ))}
            </Menu>

            <Typography className="title">
              <span className="logo-alacuenta">
                <img src={logoAlacuenta} alt="logo-alacuenta"/>
              </span>
              <div className="title-container">
                <h1 className="title-app"> alacuenta </h1>
                <span className="title-app subtitle"> .com </span>
              </div>
            </Typography>

            {this.props.showShoppingCar === true &&
            <IconButton aria-label={`${shoppingCarList.totalQuantity} new products in your car}`} color="inherit" onClick={() => {
              window.location.hash = Routes.getShoppingCar(this.props.match.params.companyAlias);
            }}>
              <Badge Key={"shopping-cart-icon-badge"} badgeContent={shoppingCarList.totalQuantity} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            }
          </Toolbar>
        }

{!this.props.isMobileVersion &&
          <Toolbar>
            <div className="title">
              <span className="logo-alacuenta">
                <img src={logoAlacuenta} alt="logo-alacuenta"/>
              </span>
              <div className="title-container">
                <h1 className="title-app"> alacuenta </h1>
                <span className="title-app subtitle"> .com </span>
              </div>
            </div>


            {this.menuItems.map(menuItem => (
              <React.Fragment key={menuItem.name}>
                {menuItem.type === 'external' &&
                <Link href={menuItem.action} target="_blank" className="menu-item-desktop" >
                  {menuItem.name}
                </Link>
                }
                {menuItem.type === 'internal' &&
                <Link onClick={menuItem.action} className="menu-item-desktop" >
                  {menuItem.name}
                </Link>
                }
              </React.Fragment>
            ))}

            {this.props.showShoppingCar === true &&
            <IconButton aria-label={`${shoppingCarList.totalQuantity} new products in your car}`} color="inherit" onClick={() => {
              window.location.hash = Routes.getShoppingCar(this.props.match.params.companyAlias);
            }}>
              <Badge badgeContent={shoppingCarList.totalQuantity} color="secondary">
                <ShoppingCartIcon />
              </Badge>
            </IconButton>
            }
          </Toolbar>
        }
      </AppBar>
    );
  }
}

const mapStateToProps = state => ({
  shoppingCarList: state.shoppingCar,
});

const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppToolBar));
