import React from 'react';
import {
  Button,
} from '@material-ui/core';
import { Paper } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class ContactPageScreen extends React.Component {
  handleBack() {
    window.history.back();
  }
  render() {
    return (
      <Paper className="container" style={{padding: 10}}>
        <h3>Contáctanos</h3>
        <p>
          Tienes alguna sugerencia o comentario, escríbenos al correo <a href="mailto:contactanos@alacuenta.com">contactanos@alacuenta.com</a>
        </p>
        <div className="container-right">
          <Button variant="contained" color="default" onClick={this.handleBack}>
            <ArrowBackIosIcon/> Volver
          </Button>
        </div>
      </Paper>
    );
  }
}

export default ContactPageScreen;
