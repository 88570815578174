import React from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IconButton } from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const styles = {
  containerSelector: { width: 160, float: 'right' },
  containerSelectorSmall: { width: 100, float: 'right', padding: 0, margin: 0 },

  numberContainer: { padding: '15px 5px' },
  numberContainerSmall: { padding: '4px 0' },

  buttonSmall: { height: 25, padding: 0 },
};

class QuantitySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: this.props.quantity,
    };
  }
  onHandlerAddProductQuantity() {
    this.setState({ quantity: this.state.quantity + 1 });
    if (this.props.onAddProduct)
      this.props.onAddProduct(this.state.quantity);
  }
  onHandlerReduceProductQuantity() {
    const newQuantity = this.state.quantity - 1;
    this.setState({ quantity: newQuantity });
    if (this.props.onRemoveProduct)
      this.props.onRemoveProduct(newQuantity);
  }
  render() {
    return (
      <div style={this.props.size === 'small' ? styles.containerSelectorSmall : styles.containerSelector}>
        <div className="flex-container">
          <IconButton color="primary" style={this.props.size === 'small' ? styles.buttonSmall : {}} disabled={this.state.quantity === 0} onClick={this.onHandlerReduceProductQuantity.bind(this)}>
            <RemoveCircleOutlineIcon />
          </IconButton>
          <div style={this.props.size === 'small' ? styles.numberContainerSmall : styles.numberContainer}>
            {this.state.quantity}
          </div>
          <IconButton color="primary" style={this.props.size === 'small' ? styles.buttonSmall : {}} onClick={this.onHandlerAddProductQuantity.bind(this)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </div>
      </div>
    );
  }
}

export default QuantitySelector;
