import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import shoppingCar from './reducers/shoppingCar';
import newProduct from './reducers/newProduct';
import productToEdit from './reducers/productToEdit';
import restaurants from './reducers/restaurants';
import savedOrders from './reducers/savedOrders';
import currentCompany from './reducers/currentCompany';
import productsToSelect from './reducers/productsToSelect';
import thunk from 'redux-thunk';

const reducer = combineReducers({
  shoppingCar,
  newProduct,
  productToEdit,
  restaurants,
  savedOrders,
  currentCompany,
  productsToSelect,
});

const store = createStore(
  reducer,
  {},
  compose(
    applyMiddleware(thunk)
    /** Only for debugging mode: **/
    //,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;