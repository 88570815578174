import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { departamentosYMunicipiosCombined } from '../hardcodedData/departamentosYMunicipiosColombia';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

class DropdownDepartamentoYMunicipio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue || '',
      isvalid: false,
    };
  }
  componentDidMount() {
    this.validate();
  }
  validate() {
    const selectedOptions = this.state.value;
    const isValid = selectedOptions ? selectedOptions.length > 0: false;
    this.setState({
      hasError: !isValid && (this.state.isDirty || this.state.value !== ''),
      errorMessage: !isValid && this.state.isDirty ? 'Debes estar dentro de una zona de cobertura' : '',
      isSuccessful: isValid && (this.state.isDirty || this.state.value !== '')
    });
    this.props.onValidate(isValid);
  }
  render() {
    var options = this.props.dataSource || departamentosYMunicipiosCombined;
    return (
      <div className="custom-search-box">
        {this.state.isSuccessful &&
          <CheckCircleIcon className='icon-input-correct' style={{ float: 'left' }} />
        }
        <Autocomplete
          style={this.state.isSuccessful ? { float: 'left', width: '90%' } : { width: '100%' }}
          id="departamento-municipio-multi-select"
          size="small"
          popupIcon={null}
          hasPopupIcon={true}
          options={options}
          getOptionLabel={(option) => option}
          defaultValue={this.props.defaultValue}
          renderInput={(params) => (
            <TextField helperText={this.state.errorMessage} error={this.state.hasError} {...params} variant="outlined" label="Zona de cobertura (Domicilio) &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;" placeholder="Zona de cobertura" />
          )}
          onChange={(event, selectedOptions, operation) => {
            this.setState({ value: selectedOptions });
            this.props.onChange(selectedOptions);
            this.validate(selectedOptions);
          }}
          onBlur={() => {
            this.setState({ isDirty: true }, () => {
              this.validate();
            });
          }}
          className={this.state.isSuccessful ? 'input-correct' : ''}
        />
      </div>
    );
  }
}

export default DropdownDepartamentoYMunicipio;