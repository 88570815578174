import React, { useState } from 'react';
import AddToCarButton from '../../controls/AddToCarButton';
import { List } from '@material-ui/core';
import { connect } from 'react-redux';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatMoney } from '../../controls/Formatter';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';

/** Collapse **/
import Collapse from '@material-ui/core/Collapse';

/** Carousel **/
import Carousel from 'react-material-ui-carousel';


class CollapsibleCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryExpanded: true //this.props.index === 0
    };
  }
  render() {
    const { category } = this.props;
    return (
      <div>
        <h3 className={`title-category ${this.state.categoryExpanded ? 'expanded' : ''}`}  onClick={() => {
          this.setState({ categoryExpanded: !this.state.categoryExpanded });
        }}>
          <span>
            {category.name}
          </span>
          <IconButton edge="start" className="menuButton" color="inherit" aria-label="menu">
            {this.state.categoryExpanded && <ExpandLessIcon />}
            {!this.state.categoryExpanded && <ExpandMoreIcon />}
          </IconButton>
        </h3>
        <Collapse in={this.state.categoryExpanded}>
          <div className="container">
            <List className="flex-container" component="nav" aria-label="productos disponibles">
              {category.products && category.products.map((product) => {
                return (
                  <Product key={product.key} product={product} {...this.props} ></Product>
                );
              })}
            </List>
          </div>
        </Collapse>
      </div>
    );
  }
}

const Product = (...props) => {
  const rest = props[0];
  const product = rest.product;
  const [isCarouselOpen, setCarouselOpen] = useState(false);
  /** Events **/
  const onOpenDialog  = () => { setCarouselOpen(true) };
  const onCloseDialog = () => { setCarouselOpen(false) };
  return (
    <>
      <div className="shopping-car-element" key={`${product.name}`}>
        <div className="content-left">
          <img onClick={(product.photos && product.photos.length > 0) ? onOpenDialog: {}} className={(product.photos && product.photos.length > 0) ? 'cursor-pointer' : ''} src={product.photoURL} alt={product.name} title={product.name} />
          <div>
            <h5>
                {product.name}
            </h5>
            <Typography color="textSecondary" gutterBottom>
                {formatMoney(product.price)}
            </Typography>
            <Typography color="textSecondary" gutterBottom>
                {product.description}
            </Typography>
            {rest.currentCompany.UID !== 'unDuejrBIqdHSwvYEunz1G4r2Vm1' &&
              <AddToCarButton
                product={product}
                quantity={rest.shoppingCarList.elementsSelected.filter(p => p.product.name === product.name).length === 1 ? rest.shoppingCarList.elementsSelected.filter(p => p.product.name === product.name)[0].quantity : 0}
              />
            }
          </div>
        </div>
      </div>
      <Dialog open={isCarouselOpen} onClose={onCloseDialog} aria-labelledby="modal-item-standard" aria-describedby="modal-item-standard">
          <DialogTitle id="alert-dialog-slide-title">{product.name}</DialogTitle>
          <DialogContent>
            <div className="carousel-container">
              <Carousel autoPlay={false}>
                {product.photos && product.photos.map(p => {
                  return (
                    <img style={{width: '100%'}} alt={product.name} src={p} />
                  );
                })}
              </Carousel>
            </div>
          </DialogContent>
        </Dialog>
    </>
  );
}

const ProductSelectorList = (props) => {
  return (
    <>
      {props.productsToSelectList &&
        <div>
          {props.productsToSelectList.categories && props.productsToSelectList.categories.map((category, index) => {
            return (
              <CollapsibleCategory key={category.id} category={category} index={index} isMobileVersion={props.isMobileVersion} {...props}></CollapsibleCategory>
            );
          })}
        </div>
      }
      <div className="bottom-spacer" ></div>
    </>
  );
}

const mapStateToProps = state => ({
  shoppingCarList: state.shoppingCar,
  currentCompany: state.currentCompany,
});

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSelectorList));
