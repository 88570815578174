export const departamentosYMunicipios = [
  {
    "id": 0,
    "departamento": "Amazonas",
    "ciudades": [
      "Leticia",
      "Puerto Nariño"
    ]
  },
  {
    "id": 1,
    "departamento": "Antioquia",
    "ciudades": [
      "Abejorral",
      "Abriaquí",
      "Alejandría",
      "Amagá",
      "Amalfi",
      "Andes",
      "Angelópolis",
      "Angostura",
      "Anorí",
      "Anzá",
      "Apartadó",
      "Arboletes",
      "Argelia",
      "Armenia",
      "Barbosa",
      "Bello",
      "Belmira",
      "Betania",
      "Betulia",
      "Briceño",
      "Buriticá",
      "Cáceres",
      "Caicedo",
      "Caldas",
      "Campamento",
      "Cañasgordas",
      "Caracolí",
      "Caramanta",
      "Carepa",
      "Carolina del Príncipe",
      "Caucasia",
      "Chigorodó",
      "Cisneros",
      "Ciudad Bolívar",
      "Cocorná",
      "Concepción",
      "Concordia",
      "Copacabana",
      "Dabeiba",
      "Donmatías",
      "Ebéjico",
      "El Bagre",
      "El Carmen de Viboral",
      "El Peñol",
      "El Retiro",
      "El Santuario",
      "Entrerríos",
      "Envigado",
      "Las Vegas, Envigado",
      "El Portal, Envigado",
      "La Magnolia, Envigado",
      "San Marcos, Envigado",
      "Pontevedra, Envigado",
      "Jardines, Envigado",
      "Villagrande, Envigado",
      "La Pradera, Envigado",
      "La Sebastiana, Envigado",
      "Las Flores, Envigado",
      "Uribe Ángel, Envigado",
      "AltodeMisael, Envigado",
      "Las Orquídeas, Envigado",
      "El Esmeraldal, Envigado",
      "Loma El Atravesado, Envigado",
      "San Mateo, Envigado",
      "Zuñiga, Envigado",
      "Bosques de Zuñiga, Envigado",
      "Loma de Las Brujas, Envigado",
      "La Pradera, Envigado",
      "El Chocho, Envigado",
      "La Inmaculada, Envigado",
      "El Chinguí, Envigado",
      "El Salado, Envigado",
      "La Mina, Envigado",
      "San Rafael, Envigado",
      "Las Antillas, Envigado",
      "El Trianón, Envigado",
      "Loma del Barro, Envigado",
      "La Paz, Envigado",
      "Las Casitas, Envigado",
      "Primavera, Envigado",
      "Milán-Vallejuelos, Envigado",
      "Alcalá, Envigado",
      "El Dorado, Envigado",
      "San José, Envigado",
      "Los Naranjos, Envigado",
      "Barrio Mesa, Envigado",
      "Zona Centro, Envigado",
      "Barrio Obrero y Bucarest., Envigado",
      "Fredonia",
      "Frontino",
      "Giraldo",
      "Girardota",
      "Gómez Plata",
      "Granada",
      "Guadalupe",
      "Guarne",
      "Guatapé",
      "Heliconia",
      "Hispania",
      "Itagüí",
      "Ituango",
      "Jardín",
      "Jericó",
      "La Ceja",
      "La Estrella",
      "La Pintada",
      "La Unión",
      "Liborina",
      "Maceo",
      "Marinilla",
      "Medellín",
      "Popular, Medellín",
      "Santa Cruz, Medellín",
      "Manrique, Medellín",
      "Aranjuez, Medellín",
      "Castilla, Medellín",
      "Doce de Octubre, Medellín",
      "Robledo, Medellín",
      "Villa Hermosa, Medellín",
      "Buenos Aires, Medellín",
      "La Candelaria, Medellín",
      "LaurelesEstadio, Medellín",
      "La América, Medellín",
      "San Javier, Medellín",
      "El Poblado, Medellín",
      "Guayabal, Medellín",
      "Belén, Medellín",
      "Montebello",
      "Murindó",
      "Mutatá",
      "Nariño",
      "Nechí",
      "Necoclí",
      "Olaya",
      "Peque",
      "Pueblorrico",
      "Puerto Berrío",
      "Puerto Nare",
      "Puerto Triunfo",
      "Remedios",
      "Rionegro",
      "Sabanalarga",
      "Sabaneta",
      "Aliadas Del Sur,Sabaneta",
      "Ancon Sur,Sabaneta",
      "Betania,Sabaneta",
      "Calle Del Banco,Sabaneta",
      "Calle Larga,Sabaneta",
      "El Carmelo Ii,Sabaneta",
      "Entreamigos,Sabaneta",
      "Holanda,Sabaneta",
      "La Barquereña,Sabaneta",
      "La Florida,Sabaneta",
      "La Doctora,Sabaneta",
      "Las Casitas,Sabaneta",
      "Los Alcázarez,Sabaneta",
      "Los Arias,Sabaneta",
      "Manuel Restrepo,Sabaneta",
      "María Auxiliadora,Sabaneta",
      "Nuestra Señora De Los Dolores,Sabaneta",
      "Paso Ancho,Sabaneta",
      "Playas De María,Sabaneta",
      "Prados De Sabaneta,Sabaneta",
      "Promisión,Sabaneta",
      "Restrepo Naranjo,Sabaneta",
      "Sabaneta Real,Sabaneta",
      "San Joaquín,Sabaneta",
      "San Rafael,Sabaneta",
      "Santa Ana,Sabaneta",
      "Tres Esquinas,Sabaneta",
      "Vegas De La Doctora,Sabaneta",
      "Vegas De San José,Sabaneta",
      "Villas Del Carmen,Sabaneta",
      "Virgen Del Carmen,Sabaneta",
      "Salgar",
      "San Andrés de Cuerquia",
      "San Carlos",
      "San Francisco",
      "San Jerónimo",
      "San José de la Montaña",
      "San Juan de Urabá",
      "San Luis",
      "San Pedro de Urabá",
      "San Pedro de los Milagros",
      "San Rafael",
      "San Roque",
      "San Vicente",
      "Santa Bárbara",
      "Santa Fe de Antioquia",
      "Santa Rosa de Osos",
      "Santo Domingo",
      "Segovia",
      "Sonsón",
      "Sopetrán",
      "Támesis",
      "Tarazá",
      "Tarso",
      "Titiribí",
      "Toledo",
      "Turbo",
      "Uramita",
      "Urrao",
      "Valdivia",
      "Valparaíso",
      "Vegachí",
      "Venecia",
      "Vigía del Fuerte",
      "Yalí",
      "Yarumal",
      "Yolombó",
      "Yondó",
      "Zaragoza"
    ]
  },
  {
    "id": 2,
    "departamento": "Arauca",
    "ciudades": [
      "Arauca",
      "Arauquita",
      "Cravo Norte",
      "Fortul",
      "Puerto Rondón",
      "Saravena",
      "Tame"
    ]
  },
  {
    "id": 3,
    "departamento": "Atlántico",
    "ciudades": [
      "Baranoa",
      "Barranquilla",
      "Campo de la Cruz",
      "Candelaria",
      "Galapa",
      "Juan de Acosta",
      "Luruaco",
      "Malambo",
      "Manatí",
      "Palmar de Varela",
      "Piojó",
      "Polonuevo",
      "Ponedera",
      "Puerto Colombia",
      "Repelón",
      "Sabanagrande",
      "Sabanalarga",
      "Santa Lucía",
      "Santo Tomás",
      "Soledad",
      "Suán",
      "Tubará",
      "Usiacurí"
    ]
  },
  {
    "id": 4,
    "departamento": "Bolívar",
    "ciudades": [
      "Achí",
      "Altos del Rosario",
      "Arenal",
      "Arjona",
      "Arroyohondo",
      "Barranco de Loba",
      "Brazuelo de Papayal",
      "Calamar",
      "Cantagallo",
      "Cartagena de Indias",
      "Cicuco",
      "Clemencia",
      "Córdoba",
      "El Carmen de Bolívar",
      "El Guamo",
      "El Peñón",
      "Hatillo de Loba",
      "Magangué",
      "Mahates",
      "Margarita",
      "María la Baja",
      "Mompós",
      "Montecristo",
      "Morales",
      "Norosí",
      "Pinillos",
      "Regidor",
      "Río Viejo",
      "San Cristóbal",
      "San Estanislao",
      "San Fernando",
      "San Jacinto del Cauca",
      "San Jacinto",
      "San Juan Nepomuceno",
      "San Martín de Loba",
      "San Pablo",
      "Santa Catalina",
      "Santa Rosa",
      "Santa Rosa del Sur",
      "Simití",
      "Soplaviento",
      "Talaigua Nuevo",
      "Tiquisio",
      "Turbaco",
      "Turbaná",
      "Villanueva",
      "Zambrano"
    ]
  },
  {
    "id": 5,
    "departamento": "Boyacá",
    "ciudades": [
      "Almeida",
      "Aquitania",
      "Arcabuco",
      "Belén",
      "Berbeo",
      "Betéitiva",
      "Boavita",
      "Boyacá",
      "Briceño",
      "Buenavista",
      "Busbanzá",
      "Caldas",
      "Campohermoso",
      "Cerinza",
      "Chinavita",
      "Chiquinquirá",
      "Chíquiza",
      "Chiscas",
      "Chita",
      "Chitaraque",
      "Chivatá",
      "Chivor",
      "Ciénega",
      "Cómbita",
      "Coper",
      "Corrales",
      "Covarachía",
      "Cubará",
      "Cucaita",
      "Cuítiva",
      "Duitama",
      "El Cocuy",
      "El Espino",
      "Firavitoba",
      "Floresta",
      "Gachantivá",
      "Gámeza",
      "Garagoa",
      "Guacamayas",
      "Guateque",
      "Guayatá",
      "Güicán",
      "Iza",
      "Jenesano",
      "Jericó",
      "La Capilla",
      "La Uvita",
      "La Victoria",
      "Labranzagrande",
      "Macanal",
      "Maripí",
      "Miraflores",
      "Mongua",
      "Monguí",
      "Moniquirá",
      "Motavita",
      "Muzo",
      "Nobsa",
      "Nuevo Colón",
      "Oicatá",
      "Otanche",
      "Pachavita",
      "Páez",
      "Paipa",
      "Pajarito",
      "Panqueba",
      "Pauna",
      "Paya",
      "Paz del Río",
      "Pesca",
      "Pisba",
      "Puerto Boyacá",
      "Quípama",
      "Ramiriquí",
      "Ráquira",
      "Rondón",
      "Saboyá",
      "Sáchica",
      "Samacá",
      "San Eduardo",
      "San José de Pare",
      "San Luis de Gaceno",
      "San Mateo",
      "San Miguel de Sema",
      "San Pablo de Borbur",
      "Santa María",
      "Santa Rosa de Viterbo",
      "Santa Sofía",
      "Santana",
      "Sativanorte",
      "Sativasur",
      "Siachoque",
      "Soatá",
      "Socha",
      "Socotá",
      "Sogamoso",
      "Somondoco",
      "Sora",
      "Soracá",
      "Sotaquirá",
      "Susacón",
      "Sutamarchán",
      "Sutatenza",
      "Tasco",
      "Tenza",
      "Tibaná",
      "Tibasosa",
      "Tinjacá",
      "Tipacoque",
      "Toca",
      "Togüí",
      "Tópaga",
      "Tota",
      "Tunja",
      "Tununguá",
      "Turmequé",
      "Tuta",
      "Tutazá",
      "Úmbita",
      "Ventaquemada",
      "Villa de Leyva",
      "Viracachá",
      "Zetaquira"
    ]
  },
  {
    "id": 6,
    "departamento": "Caldas",
    "ciudades": [
      "Aguadas",
      "Anserma",
      "Aranzazu",
      "Belalcázar",
      "Chinchiná",
      "Filadelfia",
      "La Dorada",
      "La Merced",
      "Manizales",
      "Manzanares",
      "Marmato",
      "Marquetalia",
      "Marulanda",
      "Neira",
      "Norcasia",
      "Pácora",
      "Palestina",
      "Pensilvania",
      "Riosucio",
      "Risaralda",
      "Salamina",
      "Samaná",
      "San José",
      "Supía",
      "Victoria",
      "Villamaría",
      "Viterbo"
    ]
  },
  {
    "id": 7,
    "departamento": "Caquetá",
    "ciudades": [
      "Albania",
      "Belén de los Andaquíes",
      "Cartagena del Chairá",
      "Curillo",
      "El Doncello",
      "El Paujil",
      "Florencia",
      "La Montañita",
      "Milán",
      "Morelia",
      "Puerto Rico",
      "San José del Fragua",
      "San Vicente del Caguán",
      "Solano",
      "Solita",
      "Valparaíso"
    ]
  },
  {
    "id": 8,
    "departamento": "Casanare",
    "ciudades": [
      "Aguazul",
      "Chámeza",
      "Hato Corozal",
      "La Salina",
      "Maní",
      "Monterrey",
      "Nunchía",
      "Orocué",
      "Paz de Ariporo",
      "Pore",
      "Recetor",
      "Sabanalarga",
      "Sácama",
      "San Luis de Palenque",
      "Támara",
      "Tauramena",
      "Trinidad",
      "Villanueva",
      "Yopal"
    ]
  },
  {
    "id": 9,
    "departamento": "Cauca",
    "ciudades": [
      "Almaguer",
      "Argelia",
      "Balboa",
      "Bolívar",
      "Buenos Aires",
      "Cajibío",
      "Caldono",
      "Caloto",
      "Corinto",
      "El Tambo",
      "Florencia",
      "Guachené",
      "Guapí",
      "Inzá",
      "Jambaló",
      "La Sierra",
      "La Vega",
      "López de Micay",
      "Mercaderes",
      "Miranda",
      "Morales",
      "Padilla",
      "Páez",
      "Patía",
      "Piamonte",
      "Piendamó",
      "Popayán",
      "Puerto Tejada",
      "Puracé",
      "Rosas",
      "San Sebastián",
      "Santa Rosa",
      "Santander de Quilichao",
      "Silvia",
      "Sotará",
      "Suárez",
      "Sucre",
      "Timbío",
      "Timbiquí",
      "Toribío",
      "Totoró",
      "Villa Rica"
    ]
  },
  {
    "id": 10,
    "departamento": "Cesar",
    "ciudades": [
      "Aguachica",
      "Agustín Codazzi",
      "Astrea",
      "Becerril",
      "Bosconia",
      "Chimichagua",
      "Chiriguaná",
      "Curumaní",
      "El Copey",
      "El Paso",
      "Gamarra",
      "González",
      "La Gloria (Cesar)",
      "La Jagua de Ibirico",
      "La Paz",
      "Manaure Balcón del Cesar",
      "Pailitas",
      "Pelaya",
      "Pueblo Bello",
      "Río de Oro",
      "San Alberto",
      "San Diego",
      "San Martín",
      "Tamalameque",
      "Valledupar"
    ]
  },
  {
    "id": 11,
    "departamento": "Chocó",
    "ciudades": [
      "Acandí",
      "Alto Baudó",
      "Bagadó",
      "Bahía Solano",
      "Bajo Baudó",
      "Bojayá",
      "Cantón de San Pablo",
      "Cértegui",
      "Condoto",
      "El Atrato",
      "El Carmen de Atrato",
      "El Carmen del Darién",
      "Istmina",
      "Juradó",
      "Litoral de San Juan",
      "Lloró",
      "Medio Atrato",
      "Medio Baudó",
      "Medio San Juan",
      "Nóvita",
      "Nuquí",
      "Quibdó",
      "Río Iró",
      "Río Quito",
      "Riosucio",
      "San José del Palmar",
      "Sipí",
      "Tadó",
      "Unión Panamericana",
      "Unguía"
    ]
  },
  {
    "id": 12,
    "departamento": "Cundinamarca",
    "ciudades": [
      "Agua de Dios",
      "Albán",
      "Anapoima",
      "Anolaima",
      "Apulo",
      "Arbeláez",
      "Beltrán",
      "Bituima",
      "Bogotá",
      "Usaquén , Bogotá",
      "Chapinero , Bogotá",
      "Santa Fe , Bogotá",
      "San Cristóbal , Bogotá",
      "Usme , Bogotá",
      "Tunjuelito , Bogotá",
      "Bosa , Bogotá",
      "Kennedy , Bogotá",
      "Fontibón , Bogotá",
      "Engativá , Bogotá",
      "Suba , Bogotá",
      "Barrios Unidos , Bogotá",
      "Teusaquillo , Bogotá",
      "Los Mártires , Bogotá",
      "Antonio Nariño , Bogotá",
      "Puente Aranda , Bogotá",
      "La Candelaria , Bogotá",
      "Rafael Uribe Uribe , Bogotá",
      "Ciudad Bolívar , Bogotá",
      "Sumapaz, Bogotá",
      "Bojacá",
      "Cabrera",
      "Cachipay",
      "Cajicá",
      "Caparrapí",
      "Cáqueza",
      "Carmen de Carupa",
      "Chaguaní",
      "Chía",
      "Chipaque",
      "Choachí",
      "Chocontá",
      "Cogua",
      "Cota",
      "Cucunubá",
      "El Colegio",
      "El Peñón",
      "El Rosal",
      "Facatativá",
      "Fómeque",
      "Fosca",
      "Funza",
      "Fúquene",
      "Fusagasugá",
      "Gachalá",
      "Gachancipá",
      "Gachetá",
      "Gama",
      "Girardot",
      "Granada",
      "Guachetá",
      "Guaduas",
      "Guasca",
      "Guataquí",
      "Guatavita",
      "Guayabal de Síquima",
      "Guayabetal",
      "Gutiérrez",
      "Jerusalén",
      "Junín",
      "La Calera",
      "La Mesa",
      "La Palma",
      "La Peña",
      "La Vega",
      "Lenguazaque",
      "Machetá",
      "Madrid",
      "Manta",
      "Medina",
      "Mosquera",
      "El Trebol, Mosquera",
      "Villa del Sol, Mosquera",
      "Iregui I, Mosquera",
      "Iregui II, Mosquera",
      "Porvenir Centro, Mosquera",
      "Urb. Colibri, Mosquera",
      "La Esperanza, Mosquera",
      "Cabrero, Mosquera",
      "Corredor Industrial, Mosquera",
      "Villa Maria I y II, Mosquera",
      "Urb. La Cabaña, Mosquera",
      "Urb. Serrezuelita, Mosquera",
      "Serrezuelita, Mosquera",
      "Urb. Tingua II - Petrel (Turpiales), Mosquera",
      "Parque Industrial El Cabrero, Mosquera",
      "Urb. Villa Maria IV, Mosquera",
      "Urb. San Telmo, Mosquera",
      "Villa Marcela, Mosquera",
      "Urb. San Sebastian, Mosquera",
      "Predios, Mosquera",
      "Predio, Mosquera",
      "Urb. Villa Sajonia, Mosquera",
      "Urb. Altos de San Juan, Mosquera",
      "Villa Nueva, Mosquera",
      "Urb. Puerto Vallarta, Mosquera",
      "Industria, Mosquera",
      "Urb. Alejandria 4, Mosquera",
      "Centro Cultural, Mosquera",
      "Rincon de Los Virreyes, Mosquera",
      "Torres de San Felipe, Mosquera",
      "Villa Maria III, Mosquera",
      "Predio, Mosquera",
      "Urb. Tingua, Mosquera",
      "Urb. Halcones, Mosquera",
      "Urb. Santillana, Mosquera",
      "Urb. Palo Alto, Mosquera",
      "Urb. La Arboleda, Mosquera",
      "Urb. Puerto Madero, Mosquera",
      "Urb. El Jardín, Mosquera",
      "Urb. El Paraiso, Mosquera",
      "Urb. Praderas II, Mosquera",
      "Urb. Campiñas de San Gabriel, Mosquera",
      "Predio Industria, Mosquera",
      "Urb. Nueva Castilla, Mosquera",
      "Trebol del Guali, Mosquera",
      "El Dorado, Mosquera",
      "Urb. Labranti, Mosquera",
      "Urb. El Condado del Duque, Mosquera",
      "Urb. Azulejo, Mosquera",
      "Predio, Mosquera",
      "Urb. Alicante, Mosquera",
      "La Cumbre, Mosquera",
      "La Cumbre II, Mosquera",
      "Urb. San Isidro, Mosquera",
      "Santa Ana, Mosquera",
      "Altos Santa Ana, Mosquera",
      "Urb. Puerto Alegre, Mosquera",
      "Predio Mpio, Mosquera",
      "Urb. Villa del Rocio, Mosquera",
      "Urb. Campobelo I, Mosquera",
      "Urb. Caminos de Belen, Mosquera",
      "Urb. Campobelo III, Mosquera",
      "Urb. Puerto Azul, Mosquera",
      "Urb. Puerto Plata, Mosquera",
      "Institución Educativa La Paz, Mosquera",
      "Urb. Puerto Luna, Mosquera",
      "Urb. Puerto Nuevo, Mosquera",
      "Urb. El Poblado, Mosquera",
      "Urb. Praderas, Mosquera",
      "Colegio Roberto Velandia, Mosquera",
      "Urb. El Remanso, Mosquera",
      "Urb. Torcaza, Mosquera",
      "Colegio Roberto Velandia - sede La Armonia, Mosquera",
      "Urb. Quintas del Marquez II, Mosquera",
      "Urb. Quintas del Marquez, Mosquera",
      "Urb. Panorama del Campo, Mosquera",
      "Urb. Reserva Campestre, Mosquera",
      "Urb. Sol de la Sabana, Mosquera",
      "Urb. Toscana, Mosquera",
      "Urb. Campiñas del Sol, Mosquera",
      "Urb. Las Villas, Mosquera",
      "Sector, Mosquera",
      "Barrio El Charquito 2, Mosquera",
      "Urb. Puerta Grande, Mosquera",
      "Urb. Campobelo II, Mosquera",
      "Urb. Campobelo IV, Mosquera",
      "Urb. Santa Sofia, Mosquera",
      "Urb. Zarazota, Mosquera",
      "Urb. Campobelo V, Mosquera",
      "Guarderia Melany, Mosquera",
      "Urb. Campobelo VI, Mosquera",
      "Parques de San Isidro, Mosquera",
      "Urb. Alejandria 3, Mosquera",
      "Montana, Mosquera",
      "Parque Industrial Santo Domingo, Mosquera",
      "Parque Industrial Mosquera, Mosquera",
      "Predio, Mosquera",
      "Predio, Mosquera",
      "Zona Franca de Occidente, Mosquera",
      "Predio, Mosquera",
      "Corredor Industrial Vía Madrid, Mosquera",
      "Urb. Athalea Club Residencial, Mosquera",
      "Urb. Belverde I, Mosquera",
      "Urb. Belverde II, Mosquera",
      "Urb. Hacienda Alcala Rodamonte, Mosquera",
      "Urb. Hacienda Alcala Roble, Mosquera",
      "Parque Industrial San Jorge, Mosquera",
      "Urb. Hacienda Alcala Sauce, Mosquera",
      "Cementerio Municipal, Mosquera",
      "Predio, Mosquera",
      "Urb. Hacienda Alcala Rodamonte, Mosquera",
      "Urb. Sol Creciente, Mosquera",
      "Urb. Sol Naciente, Mosquera",
      "Barrio El Charquito (1), Mosquera",
      "Barrio El Charquito (1), Mosquera",
      "Parque Industrial TecPlas, Mosquera",
      "Sector Siete Trojes (2), Mosquera",
      "Predio, Mosquera",
      "Urb. Reserva de Alcala, Mosquera",
      "Instituto Salesiano San José, Mosquera",
      "Urb. Toledo, Mosquera",
      "Urb. Alejandria 1, Mosquera",
      "Predio, Mosquera",
      "Urb. Alejandria 6, Mosquera",
      "Urb. Alejandria 5, Mosquera",
      "Urb. Alejandria 2, Mosquera",
      "Villa Olimpica, Mosquera",
      "Colegio Albert Einstein, Mosquera",
      "Parque Industrial Puerto Vallarta, Mosquera",
      "Parque Industrial de La Sabana, Mosquera",
      "Urb. La Cartuja, Mosquera",
      "Porvenir Rio, Mosquera",
      "Villa Lady, Mosquera",
      "Managua, Mosquera",
      "El Cerrito, Mosquera",
      "Planadas, Mosquera",
      "Urb. area 65, Mosquera",
      "Portal del Cerrito, Mosquera",
      "Diamante Occidental, Mosquera",
      "Fincas e Industria, Mosquera",
      "El Cerrejon, Mosquera",
      "Industria, Mosquera",
      "Diamante Oriental, Mosquera",
      "Villa Cety, Mosquera",
      "Urb. Camelias del Diamante, Mosquera",
      "Sabana, Mosquera",
      "Cartagenita, Mosquera",
      "Minipilla, Mosquera",
      "Mosquera Centro, Mosquera",
      "Maipore, Mosquera",
      "Reserva de Mallorca, Mosquera",
      "El Rubi II, Mosquera",
      "Zona Industrial, Mosquera",
      "Urb. El Refran, Mosquera",
      "Bremen, Mosquera",
      "Predio, Mosquera",
      "El Carmen, Mosquera",
      "La estancia 3, Mosquera",
      "La estancia 2, Mosquera",
      "La estancia 1, Mosquera",
      "Urb. Cortijo de Serrezuela, Mosquera",
      "El Rubi I, Mosquera",  
      "Villa Yenny, Mosquera",
      "Predio, Mosquera",
      "Urb. Reserva de San Alejo, Mosquera",
      "Parques del Diamante, Mosquera",
      "Urb. Sainz de Baranda, Mosquera",
      "Lucero, Mosquera",
      "Urbanizacion Icanti, Mosquera",
      "Urb. San Isidro, Mosquera",
      "Nariño",
      "Nemocón",
      "Nilo",
      "Nimaima",
      "Nocaima",
      "Pacho",
      "Paime",
      "Pandi",
      "Paratebueno",
      "Pasca",
      "Puerto Salgar",
      "Pulí",
      "Quebradanegra",
      "Quetame",
      "Quipile",
      "Ricaurte",
      "San Antonio del Tequendama",
      "San Bernardo",
      "San Cayetano",
      "San Francisco",
      "San Juan de Rioseco",
      "Sasaima",
      "Sesquilé",
      "Sibaté",
      "Silvania",
      "Simijaca",
      "Soacha",
      "Sopó",
      "Subachoque",
      "Suesca",
      "Supatá",
      "Susa",
      "Sutatausa",
      "Tabio",
      "Tausa",
      "Tena",
      "Tenjo",
      "Tibacuy",
      "Tibirita",
      "Tocaima",
      "Tocancipá",
      "Topaipí",
      "Ubalá",
      "Ubaque",
      "Ubaté",
      "Une",
      "Útica",
      "Venecia",
      "Vergara",
      "Vianí",
      "Villagómez",
      "Villapinzón",
      "Villeta",
      "Viotá",
      "Yacopí",
      "Zipacón",
      "Zipaquirá"
    ]
  },
  {
    "id": 13,
    "departamento": "Córdoba",
    "ciudades": [
      "Ayapel",
      "Buenavista",
      "Canalete",
      "Cereté",
      "Chimá",
      "Chinú",
      "Ciénaga de Oro",
      "Cotorra",
      "La Apartada",
      "Lorica",
      "Los Córdobas",
      "Momil",
      "Montelíbano",
      "Montería",
      "Moñitos",
      "Planeta Rica",
      "Pueblo Nuevo",
      "Puerto Escondido",
      "Puerto Libertador",
      "Purísima",
      "Sahagún",
      "San Andrés de Sotavento",
      "San Antero",
      "San Bernardo del Viento",
      "San Carlos",
      "San José de Uré",
      "San Pelayo",
      "Tierralta",
      "Tuchín",
      "Valencia"
    ]
  },
  {
    "id": 14,
    "departamento": "Guainía",
    "ciudades": [
      "Inírida"
    ]
  },
  {
    "id": 15,
    "departamento": "Guaviare",
    "ciudades": [
      "Calamar",
      "El Retorno",
      "Miraflores",
      "San José del Guaviare"
    ]
  },
  {
    "id": 16,
    "departamento": "Huila",
    "ciudades": [
      "Acevedo",
      "Agrado",
      "Aipe",
      "Algeciras",
      "Altamira",
      "Baraya",
      "Campoalegre",
      "Colombia",
      "El Pital",
      "Elías",
      "Garzón",
      "Gigante",
      "Guadalupe",
      "Hobo",
      "Íquira",
      "Isnos",
      "La Argentina",
      "La Plata",
      "Nátaga",
      "Neiva",
      "Oporapa",
      "Paicol",
      "Palermo",
      "Palestina",
      "Pitalito",
      "Rivera",
      "Saladoblanco",
      "San Agustín",
      "Santa María",
      "Suaza",
      "Tarqui",
      "Tello",
      "Teruel",
      "Tesalia",
      "Timaná",
      "Villavieja",
      "Yaguará"
    ]
  },
  {
    "id": 17,
    "departamento": "La Guajira",
    "ciudades": [
      "Albania",
      "Barrancas",
      "Dibulla",
      "Distracción",
      "El Molino",
      "Fonseca",
      "Hatonuevo",
      "La Jagua del Pilar",
      "Maicao",
      "Manaure",
      "Riohacha",
      "San Juan del Cesar",
      "Uribia",
      "Urumita",
      "Villanueva"
    ]
  },
  {
    "id": 18,
    "departamento": "Magdalena",
    "ciudades": [
      "Algarrobo",
      "Aracataca",
      "Ariguaní",
      "Cerro de San Antonio",
      "Chibolo",
      "Chibolo",
      "Ciénaga",
      "Concordia",
      "El Banco",
      "El Piñón",
      "El Retén",
      "Fundación",
      "Guamal",
      "Nueva Granada",
      "Pedraza",
      "Pijiño del Carmen",
      "Pivijay",
      "Plato",
      "Pueblo Viejo",
      "Remolino",
      "Sabanas de San Ángel",
      "Salamina",
      "San Sebastián de Buenavista",
      "San Zenón",
      "Santa Ana",
      "Santa Bárbara de Pinto",
      "Santa Marta",
      "Sitionuevo",
      "Tenerife",
      "Zapayán",
      "Zona Bananera"
    ]
  },
  {
    "id": 19,
    "departamento": "Meta",
    "ciudades": [
      "Acacías",
      "Barranca de Upía",
      "Cabuyaro",
      "Castilla la Nueva",
      "Cubarral",
      "Cumaral",
      "El Calvario",
      "El Castillo",
      "El Dorado",
      "Fuente de Oro",
      "Granada",
      "Guamal",
      "La Macarena",
      "La Uribe",
      "Lejanías",
      "Mapiripán",
      "Mesetas",
      "Puerto Concordia",
      "Puerto Gaitán",
      "Puerto Lleras",
      "Puerto López",
      "Puerto Rico",
      "Restrepo",
      "San Carlos de Guaroa",
      "San Juan de Arama",
      "San Juanito",
      "San Martín",
      "Villavicencio",
      "Vista Hermosa"
    ]
  },
  {
    "id": 20,
    "departamento": "Nariño",
    "ciudades": [
      "Aldana",
      "Ancuyá",
      "Arboleda",
      "Barbacoas",
      "Belén",
      "Buesaco",
      "Chachagüí",
      "Colón",
      "Consacá",
      "Contadero",
      "Córdoba",
      "Cuaspud",
      "Cumbal",
      "Cumbitara",
      "El Charco",
      "El Peñol",
      "El Rosario",
      "El Tablón",
      "El Tambo",
      "Francisco Pizarro",
      "Funes",
      "Guachucal",
      "Guaitarilla",
      "Gualmatán",
      "Iles",
      "Imués",
      "Ipiales",
      "La Cruz",
      "La Florida",
      "La Llanada",
      "La Tola",
      "La Unión",
      "Leiva",
      "Linares",
      "Los Andes",
      "Magüí Payán",
      "Mallama",
      "Mosquera",
      "Nariño",
      "Olaya Herrera",
      "Ospina",
      "Pasto",
      "Policarpa",
      "Potosí",
      "Providencia",
      "Puerres",
      "Pupiales",
      "Ricaurte",
      "Roberto Payán",
      "Samaniego",
      "San Bernardo",
      "San José de Albán",
      "San Lorenzo",
      "San Pablo",
      "San Pedro de Cartago",
      "Sandoná",
      "Santa Bárbara",
      "Santacruz",
      "Sapuyes",
      "Taminango",
      "Tangua",
      "Tumaco",
      "Túquerres",
      "Yacuanquer"
    ]
  },
  {
    "id": 21,
    "departamento": "Norte de Santander",
    "ciudades": [
      "Ábrego",
      "Arboledas",
      "Bochalema",
      "Bucarasica",
      "Cáchira",
      "Cácota",
      "Chinácota",
      "Chitagá",
      "Convención",
      "Cúcuta",
      "Cucutilla",
      "Duranía",
      "El Carmen",
      "El Tarra",
      "El Zulia",
      "Gramalote",
      "Hacarí",
      "Herrán",
      "La Esperanza",
      "La Playa de Belén",
      "Labateca",
      "Los Patios",
      "Lourdes",
      "Mutiscua",
      "Ocaña",
      "Pamplona",
      "Pamplonita",
      "Puerto Santander",
      "Ragonvalia",
      "Salazar de Las Palmas",
      "San Calixto",
      "San Cayetano",
      "Santiago",
      "Santo Domingo de Silos",
      "Sardinata",
      "Teorama",
      "Tibú",
      "Toledo",
      "Villa Caro",
      "Villa del Rosario"
    ]
  },
  {
    "id": 22,
    "departamento": "Putumayo",
    "ciudades": [
      "Colón",
      "Mocoa",
      "Orito",
      "Puerto Asís",
      "Puerto Caicedo",
      "Puerto Guzmán",
      "Puerto Leguízamo",
      "San Francisco",
      "San Miguel",
      "Santiago",
      "Sibundoy",
      "Valle del Guamuez",
      "Villagarzón"
    ]
  },
  {
    "id": 23,
    "departamento": "Quindío",
    "ciudades": [
      "Armenia",
      "Buenavista",
      "Calarcá",
      "Circasia",
      "Córdoba",
      "Filandia",
      "Génova",
      "La Tebaida",
      "Montenegro",
      "Pijao",
      "Quimbaya",
      "Salento"
    ]
  },
  {
    "id": 24,
    "departamento": "Risaralda",
    "ciudades": [
      "Apía",
      "Balboa",
      "Belén de Umbría",
      "Dosquebradas",
      "Guática",
      "La Celia",
      "La Virginia",
      "Marsella",
      "Mistrató",
      "Pereira",
      "Pueblo Rico",
      "Quinchía",
      "Santa Rosa de Cabal",
      "Santuario"
    ]
  },
  {
    "id": 25,
    "departamento": "San Andrés y Providencia",
    "ciudades": [
      "Providencia y Santa Catalina Islas",
      "San Andrés"
    ]
  },
  {
    "id": 26,
    "departamento": "Santander",
    "ciudades": [
      "Aguada",
      "Albania",
      "Aratoca",
      "Barbosa",
      "Barichara",
      "Barrancabermeja",
      "Betulia",
      "Bolívar",
      "Bucaramanga",
      "Cabrera",
      "California",
      "Capitanejo",
      "Carcasí",
      "Cepitá",
      "Cerrito",
      "Charalá",
      "Charta",
      "Chima",
      "Chipatá",
      "Cimitarra",
      "Concepción",
      "Confines",
      "Contratación",
      "Coromoro",
      "Curití",
      "El Carmen de Chucurí",
      "El Guacamayo",
      "El Peñón",
      "El Playón",
      "El Socorro",
      "Encino",
      "Enciso",
      "Florián",
      "Floridablanca",
      "Galán",
      "Gámbita",
      "Girón",
      "Guaca",
      "Guadalupe",
      "Guapotá",
      "Guavatá",
      "Güepsa",
      "Hato",
      "Jesús María",
      "Jordán",
      "La Belleza",
      "La Paz",
      "Landázuri",
      "Lebrija",
      "Los Santos",
      "Macaravita",
      "Málaga",
      "Matanza",
      "Mogotes",
      "Molagavita",
      "Ocamonte",
      "Oiba",
      "Onzaga",
      "Palmar",
      "Palmas del Socorro",
      "Páramo",
      "Piedecuesta",
      "Pinchote",
      "Puente Nacional",
      "Puerto Parra",
      "Puerto Wilches",
      "Rionegro",
      "Sabana de Torres",
      "San Andrés",
      "San Benito",
      "San Gil",
      "San Joaquín",
      "San José de Miranda",
      "San Miguel",
      "San Vicente de Chucurí",
      "Santa Bárbara",
      "Santa Helena del Opón",
      "Simacota",
      "Suaita",
      "Sucre",
      "Suratá",
      "Tona",
      "Valle de San José",
      "Vélez",
      "Vetas",
      "Villanueva",
      "Zapatoca"
    ]
  },
  {
    "id": 27,
    "departamento": "Sucre",
    "ciudades": [
      "Buenavista",
      "Caimito",
      "Chalán",
      "Colosó",
      "Corozal",
      "Coveñas",
      "El Roble",
      "Galeras",
      "Guaranda",
      "La Unión",
      "Los Palmitos",
      "Majagual",
      "Morroa",
      "Ovejas",
      "Sampués",
      "San Antonio de Palmito",
      "San Benito Abad",
      "San Juan de Betulia",
      "San Marcos",
      "San Onofre",
      "San Pedro",
      "Sincé",
      "Sincelejo",
      "Sucre",
      "Tolú",
      "Tolú Viejo"
    ]
  },
  {
    "id": 28,
    "departamento": "Tolima",
    "ciudades": [
      "Alpujarra",
      "Alvarado",
      "Ambalema",
      "Anzoátegui",
      "Armero",
      "Ataco",
      "Cajamarca",
      "Carmen de Apicalá",
      "Casabianca",
      "Chaparral",
      "Coello",
      "Coyaima",
      "Cunday",
      "Dolores",
      "El Espinal",
      "Falán",
      "Flandes",
      "Fresno",
      "Guamo",
      "Herveo",
      "Honda",
      "Ibagué",
      "Icononzo",
      "Lérida",
      "Líbano",
      "Mariquita",
      "Melgar",
      "Murillo",
      "Natagaima",
      "Ortega",
      "Palocabildo",
      "Piedras",
      "Planadas",
      "Prado",
      "Purificación",
      "Rioblanco",
      "Roncesvalles",
      "Rovira",
      "Saldaña",
      "San Antonio",
      "San Luis",
      "Santa Isabel",
      "Suárez",
      "Valle de San Juan",
      "Venadillo",
      "Villahermosa",
      "Villarrica"
    ]
  },
  {
    "id": 29,
    "departamento": "Valle del Cauca",
    "ciudades": [
      "Alcalá",
      "Andalucía",
      "Ansermanuevo",
      "Argelia",
      "Bolívar",
      "Buenaventura",
      "Buga",
      "Bugalagrande",
      "Caicedonia",
      "Cali",
      "Calima",
      "Candelaria",
      "Cartago",
      "Dagua",
      "El Águila",
      "El Cairo",
      "El Cerrito",
      "El Dovio",
      "Florida",
      "Ginebra",
      "Guacarí",
      "Jamundí",
      "La Cumbre",
      "La Unión",
      "La Victoria",
      "Obando",
      "Palmira",
      "Pradera",
      "Restrepo",
      "Riofrío",
      "Roldanillo",
      "San Pedro",
      "Sevilla",
      "Toro",
      "Trujillo",
      "Tuluá",
      "Ulloa",
      "Versalles",
      "Vijes",
      "Yotoco",
      "Yumbo",
      "Zarzal"
    ]
  },
  {
    "id": 30,
    "departamento": "Vaupés",
    "ciudades": [
      "Carurú",
      "Mitú",
      "Taraira"
    ]
  },
  {
    "id": 31,
    "departamento": "Vichada",
    "ciudades": [
      "Cumaribo",
      "La Primavera",
      "Puerto Carreño",
      "Santa Rosalía"
    ]
  }
];
//export const departamentosYMunicipiosCombined = departamentosYMunicipios.map(d => d.ciudades.map(c => `${d.departamento}, ${c}`));
//const departamentosYMunicipiosCombined = departamentosYMunicipios.reduce((depAndMuni, d) => Array.prototype.push.apply(depAndMuni, d.ciudades.map(c => `${d.departamento}, ${c}`)), []);
let depYMuniCombined = [];
departamentosYMunicipios.forEach(d => {
  d.ciudades.forEach(c => {
    depYMuniCombined.push(`${c}, ${d.departamento}`);
  });
});
export const departamentosYMunicipiosCombined = depYMuniCombined;

