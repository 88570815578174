import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '10px auto 0',
    width: 400,
  },
  containerMobile: {
    margin: '10px auto 0',
    width: 310,
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(9),
    float: 'left',
  },
  title: {
    float: 'left',
    marginLeft: 10,
  },
}));

export default function RestaurantProfile(props) {
    const classes = useStyles();
    return (
      <div className={props.isMobileVersion === true ? classes.containerMobile : classes.container}>
        {props.currentCompany && props.currentCompany.basicInfo && 
          <>
            <img alt="Remy Sharp" src={props.currentCompany.basicInfo.photoURL} className={classes.large} />
            <h3 className={classes.title}>{props.currentCompany.basicInfo.name}</h3>
            <div style={{clear: 'both'}}></div>
          </>
        }
      </div>
    );
}
