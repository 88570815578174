import { getRestaurantByAliasType } from '../actions/getCurrentCompany';

const defaultState = {};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case getRestaurantByAliasType: {
      const currentRestaurant = payload;
      return currentRestaurant;
    }

    default:
      return state;
  }
}

export default reducer;