import { type as getSavedOrdersType } from '../actions/getSavedOrders';
import { type as addSavedOrderType } from '../actions/addSavedOrder';

const defaultState = [];

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case getSavedOrdersType: {
      return JSON.parse(window.localStorage.getItem(`my-orders_${payload}_`)) || [];
    }
    case addSavedOrderType: {
      const { companyIdentifier, order } = payload;
      const newState = [...state, order];
      window.localStorage.setItem(`my-orders_${companyIdentifier}_`, JSON.stringify(newState));
      return newState;
    }

    default:
      return state;
  }
}

export default reducer;