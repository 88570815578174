import MenuDAO from '../../DAO/MenuDAO';

export const getMenuByUIDType = 'GET_MENU_BY_UID';

export const getMenuByUID = (UID, callback) => {
    return (dispatch, getState) => {
        MenuDAO.getMenuByUID(UID, (menu) => {
          callback(menu);
          dispatch( { type: getMenuByUIDType, payload: menu } );
        });
    }
}