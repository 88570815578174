import { getMenuByUIDType } from '../actions/getMenuByUID';

const defaultState = {};

/****************************************************************************************/
/******** Methods to parse the data from firebase into the client ********/
/****************************************************************************************/
function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case getMenuByUIDType: {
      return payload;
    }

    default:
      return state;
  }
}

export default reducer;