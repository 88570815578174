import React from 'react';
import ShoppingCar from './ShoppingCarList';
import {
  Button,
  MenuItem,
  Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import addSavedOrder from '../../redux/actions/addSavedOrder';
import clearNewProduct from '../../redux/actions/clearNewProduct';
import { connect } from 'react-redux';
import { getCurrentCompany } from '../../redux/actions/getCurrentCompany';
import getSavedOrders from '../../redux/actions/getSavedOrders';
import OrderForm from './OrderForm';
import { Routes } from '../../routes';
import setSavedOrder from '../../redux/actions/setSavedOrder';
import showNewProduct from '../../redux/actions/showNewProduct';
//import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withRouter } from "react-router-dom";
//import Tooltip from '@material-ui/core/Tooltip';
//import Fab from '@material-ui/core/Fab';
//import { makeStyles } from '@material-ui/core/styles';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import RestaurantProfile from '../../controls/RestaurantProfile';

/**
const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));
**/

/**
function FloatButtons(props) {
  const classes = useStyles();

  return (
    <div>
      <Tooltip title="Mis Pedidos Recordados" aria-label="Mis Pedidos Recordados" placement="bottom-end" onClick={props.handleOpenSavedOrders}>
        <Fab color="secondary" className={classes.absolute}>
          <StarBorderIcon/>
        </Fab>
      </Tooltip>
    </div>
  );
}
**/

class ShoppingCarScreen extends React.Component {
  constructor(props) {
    super(props);
    document.getElementsByTagName('body')[0].className = '';
    this.state = {
      savedOrdersOpener: null
    };
    this.props.getCurrentCompany(this.props.match.params.companyAlias);
    this.props.getSavedOrders(this.props.match.params.companyAlias);
    this.props.clearNewProduct();
  }
  handleNewProductClick = () => {
    window.location.hash = Routes.getProductsSelector(this.props.match.params.companyAlias);
  }
  handleOpenSavedOrders = (event) => {
    this.setState({
      savedOrdersOpener: event.currentTarget
    });
  }
  handleCloseSavedOrders = () => {
    this.setState({
      savedOrdersOpener: null
    });
  }
  handleSavedOrdersSelected = (myOrder) => {
    this.handleCloseSavedOrders();
    this.props.setSavedOrder(myOrder.order);
  }
  render() {
    const { shoppingCarList, currentCompany } = this.props;
    return (
      <>
        <RestaurantProfile isMobileVersion={ this.props.isMobileVersion } currentCompany={this.props.currentCompany} />
        <div className="container">
          <Grid container>
            <Grid container xl={6}
              direction="row"
              justify="space-around"
              alignItems="center">
              <h6 style={{margin: 0}}>Haz tu pedido aquí!</h6>
            </Grid>
            <Grid container xl={6}
              direction="row"
              justify="space-around"
              alignItems="center">
              {/*
              <Button variant="contained" color="default" aria-controls="simple-menu" aria-haspopup="true" disabled={this.props.myOrders.length === 0} onClick={this.handleOpenSavedOrders}>
                <StarBorderIcon/> Mis Pedidos Recordados
              </Button>
              */}
            </Grid>
          </Grid>

          <ShoppingCar shoppingCarList={shoppingCarList} companyIdentifier={this.props.match.params.companyAlias} currentCompany={currentCompany} />
          <div className={shoppingCarList.elementsSelected.length === 0 ? "align-evently" : ''}>
          <Popper open={Boolean(this.state.savedOrdersOpener)} anchorEl={this.state.savedOrdersOpener} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleCloseSavedOrders}>
                  <MenuList autoFocusItem={Boolean(this.state.savedOrdersOpener)} id="menu-list-grow">
                    {this.props.myOrders.map((myOrder) => {
                      return (
                      <MenuItem key={myOrder.name} onClick={() => { this.handleSavedOrdersSelected(myOrder) }}>{myOrder.name}</MenuItem>
                      );
                    })}
                  </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
            </Popper>
            <Button variant="contained" color="secondary" onClick={this.handleNewProductClick}>
              <AddIcon /> Agrega {shoppingCarList.elementsSelected.length === 0 ? 'un' : 'otro' } producto
            </Button>
          </div>
          {shoppingCarList.elementsSelected.length > 0 && 
            <OrderForm companyIdentifier={this.props.match.params.companyAlias} addSavedOrder={this.props.addSavedOrder} currentCompany={currentCompany} shoppingCarList={shoppingCarList}></OrderForm>
          }
        </div>
        {/** shoppingCarList.elementsSelected.length == 0 && 
          <FloatButtons handleOpenSavedOrders={this.handleOpenSavedOrders} />
        **/}
      </>
    );
  }
}

const mapStateToProps = state => ({
  shoppingCarList: state.shoppingCar,
  myOrders: state.savedOrders,
  currentCompany: state.currentCompany,
});

const mapDispatchToProps = {
  showNewProduct,
  getSavedOrders,
  setSavedOrder,
  addSavedOrder,
  clearNewProduct,
  getCurrentCompany,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShoppingCarScreen));
