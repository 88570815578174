import React from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Link,
} from '@material-ui/core';
import { formatMoney } from '../../controls/Formatter'
import { Paper, List, ListItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { ValidatedTextField } from '../../controls/ValidatedTextField';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import OrderDAO from '../../DAO/OrderDAO';
import DropdownDepartamentoYMunicipio from '../../controls/DropdownDepartamentoYMunicipio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Routes } from '../../routes';
import Select from '@material-ui/core/Select';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const getCurrentOrder = () => {
  return window.currentOrder || { orderID: undefined, order_id: undefined };
};

const getLinkToWhatsapp = (products, name, address, type, number, shippingPrice, phone, email, observations, paymentMethod, companyPhoneNumber, companyPaymentMethods, order_id, timestamp, pickUpInEstablishment) => {
  const NUMBER_ORDER = order_id;
  let shippingPriceInt = parseInt(shippingPrice.replace('$','').replace(',','').replace('.',''));
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const CURRENT_DATE = new Date(timestamp).toLocaleDateString('es-ES', options);
  let whatappMessage = `*Pedido No: ${NUMBER_ORDER}*    ${CURRENT_DATE}\n`;
  let whatsappProductMessages = products.elementsSelected.map(p => `*-${p.product.name}${p.quantity > 1 ? `(X${p.quantity})` : ''}${p.product.personalized ? `:* ${p.product.categories.map(c => c.options.filter(o => o.selected).map(o => o.name).join(', ')).join(' / ')}` : '*'}` );
  whatappMessage += whatsappProductMessages.join('\n');

  if(observations){
    whatappMessage += `\n*Observaciones:* ${observations}\n`;
  }

  whatappMessage += `\n\n_Subtotal:_ *${formatMoney(parseInt(products.totalPriceProducts))}*`;
  whatappMessage += `\n_Domicilio:_ *${shippingPrice}*`;
  whatappMessage += `\n_Total a pagar:_ *${`${formatMoney(parseInt(products.totalPriceProducts) + shippingPriceInt)}`}*\n`;

  whatappMessage += `\n\n*Método de pago:* ${paymentMethod}`;

  if(pickUpInEstablishment === true)
    whatappMessage += `\n\n*Recoger en establecimiento*\n`;
  else
    whatappMessage += `\n\n*Enviar a:* ${address}${type ? ` - ${type}` : ''}${number ? ` - ${number}` : ''}\n`;
  whatappMessage += `*A nombre de:* ${name}\n`;
  whatappMessage += `cel: ${phone}, email: ${email ? email : ''}\n`;

  // Remove spaces in the phoneNumber:
  companyPhoneNumber = `+57${companyPhoneNumber.replace(/\s/g, '')}`;

  return `https://wa.me/${companyPhoneNumber}?text=${encodeURIComponent(whatappMessage)}`;
};

const paymentMethodLabels = {
  'bankName': 'Banco',
  'ownerName': 'Titular',
  'documentType': 'Tipo de documento',
  'ownerIdentification': 'Número de identificación',
  'accountType': 'Tipo de cuenta',
  'accountNumber': 'Número',
  'phoneNumber': 'Número de celular'
};

const getConfirmationMessage = (products, name, address, type, number, shippingPrice, phone, email, observations, paymentMethod, companyPhoneNumber, companyPaymentMethods, order_id, timestamp, pickUpInEstablishment) => {
  const NUMBER_ORDER = order_id;
  let shippingPriceInt = parseInt(shippingPrice.replace('$','').replace(',','').replace('.',''));
  let whatsappProductMessages = (<div>
    <div className="align-between bold-text">
      <div>Detalle</div><div>Cantidad</div>
    </div>
    {products.elementsSelected.map(p => {
      return (
        <div className="align-between">
          <div>
            {p.product.name}
          </div>
          <div>
            {p.quantity}
            {p.product.personalized ? `:* ${p.product.categories.map(c => c.options.filter(o => o.selected).map(o => o.name).join(', ')).join(' / ')}` : ''}
          </div>
        </div>
      );
    })}
    <div className="align-between">
      <div>Subtotal</div><div>{formatMoney(parseInt(products.totalPriceProducts))}</div>
    </div>
    <div className="align-between">
      <div>Domicilio</div><div>{shippingPrice}</div>
    </div>
  </div>);
  return (
    <>
      <h6>Pedido No: {NUMBER_ORDER}</h6>
      <div>{whatsappProductMessages}</div>
      <div className="align-between">
        <div>Total a pagar:</div>
        <div>{`${formatMoney(parseInt(products.totalPriceProducts) + shippingPriceInt)}`}</div>
      </div>
      {observations && <div><div>Observaciones:</div>{`${observations}`}</div>}
      <div><div className="bold-text">Método de pago: </div><div>{paymentMethod}</div></div>
      {paymentMethod === paymentMethods[1] && companyPaymentMethods.length > 0 &&
        <div>
          <div style={{color: '#b41b64', fontWeight: 'bold'}}>
            Por favor recuerda estos datos de pago antes de enviar el pedido:
          </div>
          <ol>
            {companyPaymentMethods.map((pM) => {
              const paymentMethodJSX = [];
              const keys = Object.keys(pM);
              for(const index in keys){
                  const key = keys[index];
                  if(key !== 'UID' && key !== 'QrCodeImage'){
                    paymentMethodJSX.push(
                      <div><strong>{paymentMethodLabels[key]}:</strong>{pM[key]}</div>
                    );
                  }
                  if(key === 'QrCodeImage'){
                    paymentMethodJSX.push(
                      <div style={{textAlign: 'center' }}>
                        <img width={200} height={200} src={pM[key]} alt={pM[key]} title={pM[key]} />
                      </div>
                    );
                  }
              }
              return (
                <li>
                  {paymentMethodJSX}
                </li>
              );
            })}
          </ol>
        </div>
      }

      {pickUpInEstablishment === true ?
        <div><div className="bold-text">Recoger en establecimiento</div></div>
      :
        <div><div>{address}</div></div>
      }
      <div><div className="bold-text">{pickUpInEstablishment === true ? 'Entregar a:' : 'Enviar a:'}</div><div className="bold-text">{name}</div></div>
      <div><div>{type} - {number}</div></div>
      <div><div>{`cel: ${phone}, email: ${email ? email : ''}`}</div></div>
    </>
  );
};

const paymentMethods = [
  "Efectivo",
  "Transferencia"
];

class OrderForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: window.localStorage.getItem('name'),
      address: window.localStorage.getItem('address'),
      placeType: window.localStorage.getItem('placeType'),
      number: window.localStorage.getItem('number'),
      phone: window.localStorage.getItem('phone'),
      email: window.localStorage.getItem('email'),
      departamentoYMunicipioSelected: null,
      shippingPrice: '0',
      observations: null,
      pickUpInEstablishment: false,

      nameValid: false,
      addressValid: false,
      phoneValid: false,
      paymentMethod: paymentMethods[0],
      emailValid: false,
      departamentoYMunicipioSelectedValid: false,

      openDialogAddSavedOrder: false,
      openDialogConfirmOrder: false,
      orderNameValid: false,

      loading: false,
    };
  }
  handleOpenDialogAddSavedOrder = () => {
    this.setState({
      openDialogAddSavedOrder: true
    });
  }
  handleCloseDialogAddSavedOrder = () => {
    this.setState({
      openDialogAddSavedOrder: false
    });
  }
  handleOpenDialogConfirmOrder = () => {
    this.setState({
      openDialogConfirmOrder: true
    });
  }
  handleCloseDialogConfirmOrder = () => {
    this.setState({
      openDialogConfirmOrder: false
    });
  }
  render() {
    // < #63. Clear garbage from local storage:
    window.localStorage.removeItem('departamentoYMunicipioSelected');
    window.localStorage.removeItem('shippingPrice');
    // #63. Clear garbage from local storage >

    const { shoppingCarList, currentCompany } = this.props;
    const coverageZones = currentCompany.locationInfo.coverageZones;
    let coveraZonesAndPrice = [];
    if (coverageZones) {
      if (coverageZones.map) {
        coveraZonesAndPrice = coverageZones.map((zone) => {
          return `${zone.coverageZone} (${formatMoney(zone.shippingPrice)})`;
        });
      }
      else if(coverageZones.coverageZones) {
        if(coverageZones.coverageZones.map) {
          coveraZonesAndPrice = coverageZones.coverageZones.map((zone) => {
            return `${zone.coverageZone} (${formatMoney(zone.shippingPrice)})`;
          });
        }
      }
    }
    let depYMuniSelected = this.state.departamentoYMunicipioSelected;
    var defaultCoverageZone = coveraZonesAndPrice.filter((zone) => {
      return zone.indexOf(depYMuniSelected) !== -1;
    });
    defaultCoverageZone = defaultCoverageZone.length === 1 ? defaultCoverageZone[0] : undefined;
    return (
      <>
        <Paper className="content">
          <h3 style={{margin: '0 10px'}}>Dinos adónde enviar el pedido</h3>
          <List>
            <ListItem>
              <ValidatedTextField label="tu nombre" defaultValue={this.state.name} validations={[
                {type: 'isRequired', msg: 'El nombre es requerido'}
              ]} onChange={(newValue) => { this.setState({ name: newValue }); window.localStorage.setItem('name', newValue); }} onValidate={(isValid) => {this.setState({ nameValid: isValid}); }}></ValidatedTextField>
            </ListItem>
            <ListItem>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event) => { this.setState({ pickUpInEstablishment: event.target.checked }); }}
                    name="pickUpInEstablishment"
                    color="primary"
                  />
                }
                label="¿Deseas recoger en establecimiento?"
              />
            </ListItem>
            {this.state.pickUpInEstablishment === false &&
              <>
                <ListItem>
                  <DropdownDepartamentoYMunicipio
                    defaultValue={defaultCoverageZone}
                    dataSource={coveraZonesAndPrice}
                    onChange = {(departamentoYMunicipioSelected) => {
                      let parts = departamentoYMunicipioSelected.split('(');
                      departamentoYMunicipioSelected = parts[0].trim();
                      var shippingPrice = parts[1].replace(')', '');
                      this.setState({
                        departamentoYMunicipioSelected: departamentoYMunicipioSelected,
                        shippingPrice: shippingPrice
                      });
                    }}
                    onValidate = {(isValid) => {
                      this.setState({
                        departamentoYMunicipioSelectedValid: isValid
                      });
                  }} />
                </ListItem>
                <ListItem>
                  <ValidatedTextField label="tu dirección" defaultValue={this.state.address} validations={[
                    {type: 'isRequired', msg: 'La dirección es requerida'}
                  ]} onChange={(newValue) => { this.setState({ address: newValue }); window.localStorage.setItem('address', newValue); }} onValidate={(isValid) => {this.setState({ addressValid: isValid}); }}></ValidatedTextField>
                </ListItem>
                <ListItem>
                  <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.placeType}
                    onChange={(event) => { this.setState({ placeType: event.target.value }); window.localStorage.setItem('placeType', event.target.value); }}
                  >
                    <MenuItem value={"Edificio"}>Edificio</MenuItem>
                    <MenuItem value={"Torre"}>Torre</MenuItem>
                    <MenuItem value={"Casa"}>Casa</MenuItem>
                    <MenuItem value={"Apartamento"}>Apartamento</MenuItem>
                    <MenuItem value={"Otro"}>Otro</MenuItem>
                  </Select>
                  <TextField label="número" fullWidth={false} defaultValue={this.state.number}
                    helperText={this.state.errorMessage}
                    error={this.state.hasError}
                    onChange={(e) => { this.setState({ number: e.target.value }); window.localStorage.setItem('number', e.target.value); }}
                  ></TextField>
                </ListItem>
              </>
            }
            <ListItem>
              <ValidatedTextField label="número de celular" defaultValue={this.state.phone} validations={[
                {type: 'isRequired', msg: 'El celular es requerido'},
                {type: 'phone', msg: 'Ingresa un celular válido'},
              ]} onChange={(newValue) => { this.setState({ phone: newValue }); window.localStorage.setItem('phone', newValue); }} onValidate={(isValid) => {this.setState({ phoneValid: isValid}); }}></ValidatedTextField>
            </ListItem>
            <ListItem>
            <ToggleButtonGroup
                value={this.state.paymentMethod}
                exclusive
                onChange={(event, newPaymentMethod) => {
                  if (newPaymentMethod !== null) {
                    this.setState({ paymentMethod: newPaymentMethod });
                  }
                }}
                aria-label="text alignment"
              >
                <ToggleButton value={paymentMethods[0]} aria-label="left aligned">
                  {paymentMethods[0]}
                </ToggleButton>
                <ToggleButton value={paymentMethods[1]} aria-label="left aligned">
                  {paymentMethods[1]}
                </ToggleButton>
              </ToggleButtonGroup>
            </ListItem>
            <ListItem>
              <ValidatedTextField label="email (opcional)" defaultValue={this.state.email} validations={[
                {type: 'email', msg: 'Ingresa un email válido'}
              ]} onChange={(newValue) => { this.setState({ email: newValue }); window.localStorage.setItem('email', newValue); }} onValidate={(isValid) => {this.setState({ emailValid: isValid}); }}></ValidatedTextField>
            </ListItem>
            <ListItem>
              <TextField
                label="Observaciones"
                fullWidth={true}
                multiline
                rowsMax={4}
                onChange={(e) => { this.setState({ observations: e.target.value }); }}
              />
            </ListItem>
          </List>
        </Paper>
        <Paper className="content align-evently">
          {/**
          <Button variant="contained" color="default" onClick={this.handleOpenDialogAddSavedOrder}>
            <StarBorderIcon/> Recordar mi pedido
          </Button>
          **/}
          <Dialog open={this.state.openDialogAddSavedOrder} onClose={this.handleCloseDialogAddSavedOrder} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Recordar mi pedido</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Pónle un nombre a tu pedido.
              </DialogContentText>
              <ValidatedTextField label="nombre de tu pedido" validations={[
                {type: 'isRequired', msg: 'El nombre es requerido'}
              ]} onChange={(newValue) => { this.setState({ orderName: newValue }); }} onValidate={(isValid) => {this.setState({ orderNameValid: isValid}); }}></ValidatedTextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseDialogAddSavedOrder} color="secondary">
                Cancelar
              </Button>
              <Button disabled={ !this.state.orderNameValid } onClick={() => {
                this.props.addSavedOrder({ companyIdentifier: this.props.companyIdentifier, order: { name: this.state.orderName, 'order': shoppingCarList }});
                this.handleCloseDialogAddSavedOrder();
              }} color="primary">
                Crear
              </Button>
            </DialogActions>
          </Dialog>


          <Dialog open={this.state.openDialogConfirmOrder} onClose={this.handleCloseDialogConfirmOrder} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="align-center purple-text">Confirma tu pedido</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.state.confirmationMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions className="align-between">
              <Button onClick={this.handleCloseDialogConfirmOrder}>
                MODIFICAR PEDIDO
              </Button>
              <Link href={this.state.linkToWhatsapp} target="_blank" rel="noopener noreferrer" onClick={() => {
                setTimeout(() => {
                  const currentOrder  = getCurrentOrder();
                  OrderDAO.activateOrder(currentOrder.orderID, (isSuccessful) => {
                    sessionStorage.clear();
                    window.location.hash = Routes.getProductsSelector(currentCompany.basicInfo.alias);
                    window.location.reload();
                  });
                }, 500);
              }}>
                ENVIAR PEDIDO
              </Link>
            </DialogActions>
          </Dialog>


          <Button variant="contained" color="primary" style={{ backgroundColor: '#25D366' }} disabled={!this.state.nameValid || !this.state.phoneValid || !this.state.emailValid || (!this.state.pickUpInEstablishment ? (!this.state.addressValid || !this.state.departamentoYMunicipioSelectedValid): false)} onClick={() => {
            this.setState({ loading: true });
            const payloadOrder = {
              shoppingCarList: shoppingCarList,
              contactInfo: {
                name: this.state.name,
                phone: this.state.phone,
                email: this.state.email,
                observations: this.state.observations
              },
              UID: currentCompany.UID
            };
            payloadOrder['pickUpInEstablishment'] = this.state.pickUpInEstablishment;
            if (this.state.pickUpInEstablishment === false) {
              payloadOrder['contactInfo']['address'] = this.state.address;
              payloadOrder['contactInfo']['placeType'] = this.state.placeType;
              payloadOrder['contactInfo']['number'] = this.state.number;
              payloadOrder['contactInfo']['city'] = this.state.departamentoYMunicipioSelected;
            }
            const { orderID, order_id}  = getCurrentOrder();
            if(orderID) {
              payloadOrder['id'] = orderID;
              payloadOrder['order_id'] = order_id;
              OrderDAO.updateOrder(payloadOrder, (order) => {
                this.setState({
                  linkToWhatsapp: getLinkToWhatsapp(shoppingCarList, this.state.name, this.state.address, this.state.placeType, this.state.number, this.state.shippingPrice, this.state.phone, this.state.email, this.state.observations, this.state.paymentMethod, currentCompany.basicInfo.whatsappNumber, currentCompany.paymentMethods, order.order_id, order.timestamp, this.state.pickUpInEstablishment),
                  confirmationMessage: getConfirmationMessage(shoppingCarList, this.state.name, this.state.address, this.state.placeType, this.state.number, this.state.shippingPrice, this.state.phone, this.state.email, this.state.observations, this.state.paymentMethod, currentCompany.basicInfo.whatsappNumber, currentCompany.paymentMethods, order.order_id, order.timestamp, this.state.pickUpInEstablishment),
                  loading: false,
                }, () => {
                  this.handleOpenDialogConfirmOrder();
                });
              });
            }
            else {
              OrderDAO.createOrder(payloadOrder, (order) => {
                window.currentOrder = { orderID: order.id, order_id: order.order_id };
                this.setState({
                  linkToWhatsapp: getLinkToWhatsapp(shoppingCarList, this.state.name, this.state.address, this.state.placeType, this.state.number, this.state.shippingPrice, this.state.phone, this.state.email, this.state.observations, this.state.paymentMethod, currentCompany.basicInfo.whatsappNumber, currentCompany.paymentMethods, order.order_id, order.timestamp, this.state.pickUpInEstablishment),
                  confirmationMessage: getConfirmationMessage(shoppingCarList, this.state.name, this.state.address, this.state.placeType, this.state.number, this.state.shippingPrice, this.state.phone, this.state.email, this.state.observations, this.state.paymentMethod, currentCompany.basicInfo.whatsappNumber, currentCompany.paymentMethods, order.order_id, order.timestamp, this.state.pickUpInEstablishment),
                  loading: false,
                }, () => {
                  this.handleOpenDialogConfirmOrder();
                });
              });
            }
          }}>
            {this.state.loading && <CircularProgress size={24} />}
            <WhatsAppIcon/> Enviar
          </Button>
        </Paper>
      </>
    );
  }
}

export default OrderForm;