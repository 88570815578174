import { SERVICE_PREFIX } from '../config-front';

const service_prefix = SERVICE_PREFIX;
const PREFIX_ROLE = 'user';

export default class MenuDAO {
/****************************************************************************************/
/******** Menu ********/
/****************************************************************************************/
  static getMenuByUID(UID, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/menu/${UID}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
}
