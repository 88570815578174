import React from 'react';
import { connect } from 'react-redux';
import { Routes } from '../../routes';
import ProductDetail from './CustomProductDetail';
import addProduct from '../../redux/actions/addProduct';
import showNewProduct from '../../redux/actions/showNewProduct';
import { withRouter } from "react-router-dom";
import { getCurrentCompany } from '../../redux/actions/getCurrentCompany';
import { isEmptyObject } from '../../utils/utils';

class NewCustomProductScreen extends React.Component {
  constructor(props){
    super(props);
    document.getElementsByTagName('body')[0].className = '';
    this.props.getCurrentCompany(this.props.match.params.companyAlias);
    this.props.showNewProduct(this.props.match.params.companyAlias);
  }
  render() {
    const { newProductToShow, currentCompany } = this.props;
    return (
      !isEmptyObject(newProductToShow) &&
      <div className="container">
        <ProductDetail currentCompany={currentCompany} product={newProductToShow} buttonText="Agregar al carrito" handleProductOperation={(newProduct) => {
          this.props.addProduct(newProduct);
          window.location.hash = Routes.getProductsSelector(this.props.match.params.companyAlias);
        }}></ProductDetail>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  newProductToShow: state.newProduct,
  currentCompany: state.currentCompany,
});

const mapDispatchToProps = {
  showNewProduct,
  addProduct,
  getCurrentCompany,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewCustomProductScreen));
