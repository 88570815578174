import React from 'react';
import { connect } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { formatMoney } from '../../controls/Formatter'
import List from '@material-ui/core/List';
import { IconButton, Divider } from '@material-ui/core';
import QuantitySelector from '../../controls/QuantitySelector';
import { Routes } from '../../routes';
import showEditProduct from '../../redux/actions/showEditProduct';
import Typography from '@material-ui/core/Typography';
import deleteProduct from '../../redux/actions/deleteProduct';
import addProduct from '../../redux/actions/addProduct';
import removeProduct from '../../redux/actions/removeProduct';

class ShoppingCarList extends React.Component {
    handleEditProductClick = (product) => {
        this.props.showEditProduct(product);
        window.location.hash = Routes.getEditProduct(this.props.companyIdentifier, product.name);
    }
    handleDeleteProductClick = (product) => {
        this.props.deleteProduct({ product: product, currentCompany: this.props.currentCompany });
    }
    onAddProduct = (element) => {
      this.props.addProduct({ product: element.product, currentCompany: this.props.currentCompany });
    }
    onRemoveProduct = (element) => {
      this.props.removeProduct({ product: element.product, currentCompany: this.props.currentCompany });
    }
    render() {
        return (
            <>
                <List component="nav" aria-label="productos seleccionados">
                    {this.props.shoppingCarList.elementsSelected.map((element) => {
                        return (
                            <div className="shopping-car-element" key={`${element.product.name}`}>
                                <div className="content-left">
                                    <img className="product-image" src={element.product.photoURL} alt={element.product.name} title={element.product.name} />
                                    <div>
                                        <h5>
                                            {element.product.name}
                                        </h5>
                                        <Typography color="textSecondary" gutterBottom>
                                            {formatMoney(element.product.price)}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="content-right">
                                    <div>
                                        {element.product.personalized === true &&
                                            <IconButton edge="end" aria-label="comments" onClick={() => { this.handleEditProductClick(element.product); }}>
                                                <EditIcon />
                                            </IconButton>
                                        }
                                        <IconButton className="delete-product" aria-label="delete-product" onClick={() => { this.handleDeleteProductClick(element.product); }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                    <div>
                                        <Typography variant="h5" component="h2" className="price-text-white">
                                        {formatMoney(element.sumPrice)}
                                        </Typography>
                                    </div>
                                    <QuantitySelector quantity={element.quantity} size="small" onAddProduct={(quantity) => { this.onAddProduct(element) }} onRemoveProduct={(quantity) => { this.onRemoveProduct(element) }} />
                                </div>
                            </div>
                        );
                    })}
                    {/*
                    this.props.shoppingCarList.elementsSelected.length > 0 && this.props.currentCompany.locationInfo &&
                        <div style={{ margin: '10px 10px 0 10px' }}>
                            <Typography variant="h5" component="h2" className="price-text-white">
                                {formatMoney(this.props.currentCompany.locationInfo.shippingPrice)}
                            </Typography>
                            <Typography variant="h5" component="span" style={{ float: 'right', marginRight: 10 }}>
                                <DirectionsBikeIcon/> Domicilio: 
                            </Typography>
                            <div style={{clear: 'both'}}></div>
                        </div>
                    */}
                </List>
                <div styles={{clear: 'both'}}></div>
                {this.props.shoppingCarList.elementsSelected.length > 0 && 
                    <>
                        <div style={{margin: '0 10px'}}>
                            <Divider />
                            <div style={{margin: '10px 0'}}>
                                <Typography variant="h5" component="h2" className="price-text-white">
                                    SUBTOTAL: {formatMoney(this.props.shoppingCarList.totalPriceProducts)}
                                </Typography>
                            </div>
                        </div>
                        <div style={{clear: 'both'}}></div>
                    </>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    currentCompany: state.currentCompany,
});

const mapDispatchToProps = {
  showEditProduct,
  deleteProduct,
  addProduct,
  removeProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCarList);
