import { SERVICE_PREFIX } from '../config-front';

const service_prefix = SERVICE_PREFIX;
const PREFIX_ROLE = 'user';

export default class MenuDAO {
  static createOrder(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/order`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ payload: payload }),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static updateOrder(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/order`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ payload: payload }),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
  static activateOrder(payload, callback) {
    fetch(`${service_prefix}/${PREFIX_ROLE}/order/activate`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ payload: payload }),
    })
    .then(res => { return res.json(); })
    .then(data => {
      callback(data);
    });
  }
}
