import React from 'react';
import {
  Paper,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  RadioGroup,
  Radio,
  Button,
  Avatar,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import '../../App.css';
import { connect } from 'react-redux';
import { Routes } from '../../routes';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { formatMoney } from '../../controls/Formatter';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TotalElementsAllowedPerCategory = props => {
  const numSelected = Object.entries(props.state).filter(element => { return element[0].indexOf(`${props.category.name}-`) === 0 }).reduce((total, element) => element[1] === true ? total + 1 : total,0);
  let remainedElements = props.category.allowedElements - numSelected;
  remainedElements = remainedElements < 0 ? 0 : remainedElements;
  return (
    <span style={{ color: remainedElements === 0 ? '#f00' : '#0a0' }}>{` (${remainedElements} incluídos) `}</span>
  );
};
/** 
 * Commented due to the issue shown in the compilation time: 
 * Line 101:35:  Do not mutate state directly. Use setState()  react/no-direct-mutation-state
 * const calculateRemainedElements = (props, category) => {
  const numSelected = Object.entries(props.state).filter(element => { return element[0].indexOf(`${category.name}-`) === 0 }).reduce((total, element) => element[1] === true ? total + 1 : total,0);
  let remainedElements = category.allowedElements - numSelected;
  return remainedElements;
};
 */

class CustomProductDetail extends React.Component {
  constructor(props) {
    super(props);
    let initialState = {
      openCancelConfirmationDialog: false,
      remainedElements: []
    };
    this.props.product.categories.filter(category => category.type === 'singleOption').forEach((category) => {
      initialState[category.name] = category.options.filter(o => o.selected === true)[0].name
    });
    this.props.product.categories.filter(category => category.type === 'multipleOption').forEach((category) => {
      category.options.forEach((option) => {
        initialState[`${category.name}-${option.name}`] = option.selected
      });
    });
    this.state = initialState;
  }

  handleOpenCancelConfirmationDialog() {
    this.setState({
      openCancelConfirmationDialog: true
    });
  }

  handleCloseCancelConfirmationDialog() {
    this.setState({
      openCancelConfirmationDialog: false
    });
  }

  render() {
    return (
      <div>
        <div>
          <Avatar style={{marginRight: 10, width: 100, height: 100, float: 'left'}} src={this.props.currentCompany.logoUrl} />
          <h3 className="content" style={{paddingTop:40}}>{this.props.currentCompany.name}</h3>
        </div>
        <div style={{clear: 'both'}}></div>
        <Paper elevation={3}>
          <h3 className="content">{this.props.product.name} {this.props.product.price ? <span className="price-text-white">{formatMoney(this.props.product.price)}</span> : ''}</h3>
        </Paper>
        {this.props.product.categories.map((category) => {
          return (
            <React.Fragment key={category.name}>
              <Paper key={category.name} className="content">
                <h4>{category.name}{category.allowedElements && <TotalElementsAllowedPerCategory category={category} state={this.state} />}</h4>
                  {category.type === "multipleOption" && 
                    <List>
                      {category.options.map((option) => {
                        return (
                          <ListItem key={option.name}>
                            <FormControlLabel
                              disabled={ this.state.remainedElements[category.name] <= 0 && !this.state[`${category.name}-${option.name}`] }
                              control={<Checkbox name="checkedA" />}
                              label={option.name} 
                              checked={this.state[`${category.name}-${option.name}`]}
                              onChange={(event) => { 
                                this.setState({ [`${category.name}-${option.name}`]: event.target.checked }, () => {
                                  /** 
                                   * Commented due to the issue shown in the compilation time: 
                                   * Line 101:35:  Do not mutate state directly. Use setState()  react/no-direct-mutation-state
                                   * this.state.remainedElements[category.name] = calculateRemainedElements(this, category);
                                   */
                                  this.setState({ remainedElements: this.state.remainedElements });
                                });
                              }}>
                            </FormControlLabel>
                          </ListItem>
                        );
                      })}
                    </List>
                  }
                  {category.type === "singleOption" && 
                    <RadioGroup aria-label={category.name} name={category.name} value={this.state[category.name]} onChange={(event) => { this.setState({[category.name]: event.target.value}); }}>
                      {category.options.map((option) => {
                        return (
                          <ListItem key={option.name}>
                            <FormControlLabel control={<Radio />} label={option.name} value={option.name}></FormControlLabel>
                          </ListItem>
                        );
                      })}
                    </RadioGroup> 
                  }
              </Paper>
            </React.Fragment>
          );
        })}
        <Paper className="content align-evently">
          <Button variant="contained" color="secondary" onClick={() => {
              this.handleOpenCancelConfirmationDialog();
            }}>
            Cancelar
          </Button>

          <Dialog
            open={this.state.openCancelConfirmationDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={this.handleCloseCancelConfirmationDialog.bind(this)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle id="alert-dialog-slide-title">Confirmación</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                ¿Estás seguro que deseas cancelar este pedido?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseCancelConfirmationDialog.bind(this)} color="primary">
                No
              </Button>
              <Button onClick={() => {
                  this.handleCloseCancelConfirmationDialog();
                  window.location.hash = Routes.getProductsSelector(this.props.currentCompany.identifier);
                }} color="primary">
                Sí
              </Button>
            </DialogActions>
          </Dialog>


          <Button variant="contained" color="primary" onClick={(event) => {
            const keys = Object.keys(this.state).filter(k => k !== 'openCancelConfirmationDialog' && k !== 'remainedElements');
            let newProduct = this.props.product;

            /** Clean newProduct: **/
            newProduct.categories.forEach((category) => {
              category.options.forEach((option) => {
                option.selected = false;
              });
            });
            keys.forEach((key) => {
              let [categoryName, optionName] = key.split('-');
              let value = undefined;
              if(optionName)
                value = this.state[key];
              else{
                optionName = this.state[key];
                value = true;
              }
              newProduct.categories.filter(c => c.name === categoryName)[0]
                .options.filter(o => o.name === optionName)[0].selected = value;
            });
            this.props.handleProductOperation(newProduct);
           }}>
            {this.props.buttonText}
          </Button>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  productsSelected: state.shoppingCar,
});

export default connect(mapStateToProps)(CustomProductDetail);
