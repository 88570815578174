import { type as showEditProductType } from '../actions/showEditProduct';
const defaultState = {};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case showEditProductType: {
      return payload;
    }

    default:
      return state;
  }
}

export default reducer;