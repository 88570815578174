import React from 'react';
import './App.css';
import {
  Switch,
  Route,
  HashRouter,
} from "react-router-dom";
import AppToolBar from './controls/AppToolBar';
import EditCustomProductScreen from './components/customProduct/EditCustomProductScreen';
import { NEW_CUSTOM_PRODUCT, EDIT_CUSTOM_PRODUCT, SHOPPING_CAR, PRODUCTS_SELECTOR, FAQ, CONTACT_PAGE } from './routes';
import NewCustomProductScreen from './components/customProduct/NewCustomProductScreen';
import ProductSelectorScreen from './components/productSelector/ProductSelectorScreen';
import ShoppingCarScreen from './components/shoppingCar/ShoppingCarScreen';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider } from '@material-ui/core/styles';
import alacuentaTheme from './themes/alacuentaTheme';
import FAQScreen from './components/faq/FAQScreen';
import ContactPageScreen from './components/contactPage/ContactPageScreen';

const App = (props) => {
  const isMobileVersion = useMediaQuery('(max-width:600px)');
  return (
    <div className="App">
      <ThemeProvider theme={alacuentaTheme}>
        <HashRouter>
          <Switch>
            <Route path={NEW_CUSTOM_PRODUCT}>
              <AppToolBar isMobileVersion={isMobileVersion} showShoppingCar={true} />
              <NewCustomProductScreen />
            </Route>
            <Route path={EDIT_CUSTOM_PRODUCT}>
              <AppToolBar isMobileVersion={isMobileVersion} showShoppingCar={true} />
              <EditCustomProductScreen />
            </Route>
            <Route path={SHOPPING_CAR}>
              <AppToolBar isMobileVersion={isMobileVersion} />
              <ShoppingCarScreen isMobileVersion={isMobileVersion} />
            </Route>
            <Route path={FAQ}>
              <AppToolBar isMobileVersion={isMobileVersion} showShoppingCar={true} />
              <FAQScreen isMobileVersion={isMobileVersion} />
            </Route>
            <Route path={CONTACT_PAGE}>
              <AppToolBar isMobileVersion={isMobileVersion} showShoppingCar={true} />
              <ContactPageScreen isMobileVersion={isMobileVersion} />
            </Route>
            <Route path={PRODUCTS_SELECTOR}>
              <AppToolBar isMobileVersion={isMobileVersion} showShoppingCar={true} />
              <ProductSelectorScreen isMobileVersion={isMobileVersion} />
            </Route>
            {/*<Route path={RESTAURANTS_LIST}>
              <AppToolBar isMobileVersion={isMobileVersion} />
              <RestaurantsListScreen />
            </Route>*/}
          </Switch>
        </HashRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
