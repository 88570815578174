import React from 'react';
import { TextField } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator="." 
      decimalSeparator=","
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export class ValidatedTextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.defaultValue || '',
      isDirty: false
    };
  }
  componentDidMount() {
    this.validate();
  }
  validate() {
    let isValid = true;
    for (let i = 0; i < this.props.validations.length; i++) {
      const {type, msg} = this.props.validations[i];
      switch(type) {
        case 'isRequired':
          isValid = (this.state.value !== "");
          break;
        case 'email':
          if(this.state.value !== "") { isValid = this.state.value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) != null; }
          break;
        case 'phone':
          if(this.state.value !== "") { isValid = this.state.value.match(/^[0-9]{10}$/) != null; }
          break;
        case 'money':
          if(this.state.value !== "") { isValid = this.state.value.toString().match(/^[0-9]*$/) != null; }
          break;
        default:
          break;
      }
      this.setState({
        hasError: !isValid && (this.state.isDirty || this.state.value !== ''),
        errorMessage: !isValid && this.state.isDirty ? msg : '',
        isSuccessful: isValid && (this.state.isDirty || this.state.value !== '')
      });
      if (!isValid) { i = this.props.validations.length; } 
    }
    this.props.onValidate(isValid);
  }
  render() {
    let fullWidth = true;
    if (this.props.fullWidth === false)
      fullWidth = this.props.fullWidth;
    return (
      <>
        {this.state.isSuccessful &&
          <CheckCircleIcon className='icon-input-correct' />
        }
        <TextField label={this.props.label} fullWidth={fullWidth} defaultValue={this.state.value}
          helperText={this.state.errorMessage}
          error={this.state.hasError}
          onChange={(e) => { this.setState({ value: e.target.value }); this.props.onChange(e.target.value); }}
          onBlur={() => {
            this.setState({ isDirty: true }, () => {
              this.validate();
            });
          }}
          InputProps={
            this.props.validations.filter(v => v.type === 'money').length? { 
              inputComponent: NumberFormatCustom
            } : {}
          }
          className={this.state.isSuccessful ? 'input-correct' : ''}
        ></TextField>
      </>
    );
  }
}