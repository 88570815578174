import React from 'react';
import Divider from '@material-ui/core/Divider';
import {
  Button,
} from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Paper } from '@material-ui/core';
import Help from '@material-ui/icons/Help';
import { BACK_ESTABLECIMIENTOS } from '../../routes';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class FAQScreen extends React.Component {
  handleBack() {
    window.history.back();
  }
  render() {
    return (
      <Paper className="container" style={{padding: 10}}>
        <div className="container-right">
          <Button variant="contained" color="default" onClick={this.handleBack}>
            <ArrowBackIosIcon/> Volver
          </Button>
        </div>
        <h3>Preguntas frecuentes</h3>
        <List component="nav" aria-label="requent questions">
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿Qué es alacuenta.com?"
              secondary="Alacuenta.com es una plataforma online que permite a los negocios minoristas tales como restaurantes, tiendas, farmacias, ferreterias, variedades y otros mostrar un catálogo interactivo de sus productos para que los interesados en dichos productos puedan elegirlos y pedirlos de manera sencilla y rápida a través de WhatsApp ya sea desde un equipo móvil o desde un computador."
              />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="Tengo un negocio, ¿Cómo puedo registrarlo en alacuenta.com?"
              secondary={
                <>
                  Debes ingresar a <a href={ BACK_ESTABLECIMIENTOS } target='_blank' rel="noopener noreferrer">{ BACK_ESTABLECIMIENTOS }</a> y auténticarte con un usuario de Google o Gmail, posteriormente debes seguir los pasos que se te indicarán en la plataforma.
                </>
              }
              />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿Puedo pagar a través de alacuenta.com?"
              secondary="Por el momento la plataforma solo está habilitada para hacer los pedidos."
              />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿Qué son mis pedidos recordados?"
              secondary="Es una opción que te permite guardar un pedido en particular que realizas frecuentemente, para que luego lo puedas realizar fácilmente."
              />
          </ListItem>
          <Divider />
          {/**
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿Cómo puedo recordar un pedido?"
              secondary="Una vez creas tu primer pedido debes agregarlo mediante el botón “recordar mi pedido” y cuando desees volver a hacer la misma compra solo debes buscarlo en la lista de mis pedidos recordados."
              />
          </ListItem>
          <Divider />
          **/}
          <ListItem>
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText
              primary="¿Cómo hago un pedido en alacuenta.com?"
              secondary="Hay dos opciones: La más fácil es que el establecimiento al que le vas a comprar te envíe el link con su menú o catálogo, una vez estés ahí solo tienes que buscar las categorías que quieras y desplegar los productos dentro de esas categorías para que los agregues uno a uno al carrito de compras. A medida que vas agregando productos la plataforma te va calculando el valor total a pagar.
              Cuando ya tengas tu pedido completo, vas al carrito de compras y diligencias tus datos para el envío del pedido y das clic en el botón “enviar”. En ese momento el pedido se procesa como un mensaje de whatsapp que le llega al establecimiento de manera fácil para que lo pueda procesar rápido y cuentas con un identificador para hacer el seguimiento."
              />
          </ListItem>
          <Divider />
        </List>
        <div className="container-right">
          <Button variant="contained" color="default" onClick={this.handleBack}>
            <ArrowBackIosIcon/> Volver
          </Button>
        </div>
      </Paper>
    );
  }
}

export default FAQScreen;
