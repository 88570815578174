import { type as showNewProductType } from '../actions/showNewProduct';
import { type as clearNewProductType } from '../actions/clearNewProduct';
const defaultState = {};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case showNewProductType: {
      const newProduct = {
        name: 'Arma tu menú',
        price: 10000,
        personalized: true,
        categories: [
          {
            name: 'Bases',
            type: 'multipleOption',
            allowedElements: 2,
            options: [
              { name: 'Quinoa', price: 1000, selected: false },
              { name: 'Arroz integral', price: 1000, selected: false },
              { name: 'Chilli sin carne', price: 1000, selected: false },
              { name: 'Pasta Corta', price: 1000, selected: false },
              { name: 'Noodles zucchini', price: 1000, selected: false },
              { name: 'Lechuga romana', price: 1000, selected: false },
              { name: 'Kale', price: 1000, selected: false }
            ]
          },
          {
            name: 'Proteína',
            type: 'singleOption',
            options: [
              { name: 'Res', selected: true },
              { name: 'Pollo', selected: false },
              { name: 'Falafe', selected: false },
              { name: 'Champiñones salteados', selected: false }
            ]
          },
          {
            name: 'Vegetales',
            type: 'singleOption',
            options: [
              { name: 'Brócoli', selected: true },
              { name: 'Zanahoria', selected: false },
              { name: 'Tomates Cherry', selected: false },
              { name: 'Maíz fresco', selected: false },
              { name: 'Pico de gallo', selected: false },
              { name: 'Pimentónes salteados', selected: false },
              { name: 'Huevo', selected: false },
              { name: 'Cebollas encurtidas', selected: false },
              { name: 'Aguacate', selected: false },
            ]
          },
          {
            name: 'Especial',
            type: 'multipleOption',
            allowedElements: 3,
            options: [
              { name: 'Parmesano', price: 1500, selected: false },
              { name: 'Parmesano crocante', price: 1500, selected: false },
              { name: 'Queso mozzarella', price: 1500, selected: false },
              { name: 'Semillas de calabaza', price: 1500, selected: false },
              { name: 'Hummus tradicional', price: 1500, selected: false },
              { name: 'Hummus de chipotle', price: 1500, selected: false },
            ]
          },
          {
            name: 'Crocante',
            type: 'multipleOption',
            allowedElements: 2,
            options: [
              { name: 'Cebollas francesas', price: 2000, selected: false },
              { name: 'Remolacha crocante', price: 2000, selected: false },
              { name: 'Garbanzos crocantes', price: 2000, selected: false },
              { name: 'Lentejas crocantes', price: 2000, selected: false },
              { name: 'Semillas de Calabaza', price: 2000, selected: false },
              { name: 'mix oriental', price: 2000, selected: false },
            ]
          },
          {
            name: 'Vinagreta',
            type: 'multipleOption',
            allowedElements: 2,
            options: [
              { name: 'Amapola limón', price: 2000, selected: false },
              { name: 'Berenjenas asadas', price: 2000, selected: false },
              { name: 'Garbanzos', price: 2000, selected: false },
              { name: 'Miel mostaza', price: 2000, selected: false },
              { name: 'Balsámica', price: 2000, selected: false },
              { name: 'Chipotle', price: 2000, selected: false },
              { name: 'Mexicana', price: 2000, selected: false },
            ]
          }
        ]
      };
      return newProduct;
    }
    case clearNewProductType: {
      return {};
    }

    default:
      return state;
  }
}

export default reducer;