import React from 'react';
import ProductSelectorList from './ProductSelectorList';
import { connect } from 'react-redux';
import { formatMoney } from '../../controls/Formatter'
import { getMenuByUID } from '../../redux/actions/getMenuByUID';
import { setShippingPrice } from '../../redux/actions/setShippingPrice';
import { getCurrentCompany } from '../../redux/actions/getCurrentCompany';
import Snackbar from '@material-ui/core/Snackbar';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { IconButton, Badge } from '@material-ui/core';
import { Routes } from '../../routes';
import { withRouter } from "react-router-dom";
import RestaurantProfile from '../../controls/RestaurantProfile';
import { CircularProgress } from '@material-ui/core';

class ProductSelectorScreen extends React.Component {
  constructor(props){
    super(props);
    document.getElementsByTagName('body')[0].className = '';
    this.state = {
      loadingRestaurant: true,
      loadingProducts: false,
    };
    this.props.getCurrentCompany(this.props.match.params.companyAlias, (restaurant) => {
      this.setState({ loadingRestaurant: false });
      if(!restaurant.empty)
        this.setState({ loadingProducts: true });
        this.props.getMenuByUID(restaurant.UID, (menu) => {
          this.setState({ loadingProducts: false });
        });
    });
  }
  render() {
    const { productsToSelectList, shoppingCarList, isMobileVersion } = this.props;
    return (
      <>
        {this.props.loadingRestaurant &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={24} />
          </div>
        }
        <RestaurantProfile isMobileVersion={ isMobileVersion } currentCompany={this.props.currentCompany} />
        {this.props.currentCompany.empty === true &&
          <div>
            <h3 style={{ textAlign: 'center' }} >El restaurante no existe</h3>
          </div>
        }

        {this.props.loadingProducts &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress size={24} />
          </div>
        }
        {productsToSelectList.visible === false &&
          <div>
            <h3 style={{ textAlign: 'center' }} >Esta lista de productos aún no está disponible</h3>
          </div>
        }
        {productsToSelectList.visible === true &&
          <div className="container-full">
            <p>Selecciona tus productos:</p>
            <ProductSelectorList isMobileVersion={isMobileVersion} productsToSelectList={productsToSelectList}></ProductSelectorList>
            {this.props.currentCompany.UID !== 'unDuejrBIqdHSwvYEunz1G4r2Vm1' &&
              <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={true}
                message={<div>Valor de tu compra: <span className="price-text">{formatMoney(shoppingCarList.totalPriceProducts)}</span></div>}
                key={'bottom center'}
                action={
                  <IconButton color="primary" onClick={() => {
                    window.location.hash = Routes.getShoppingCar(this.props.match.params.companyAlias);
                  }}>
                    <Badge badgeContent={shoppingCarList.totalQuantity}>
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                }
              />
            }
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = state => ({
  productsToSelectList: state.productsToSelect,
  shoppingCarList: state.shoppingCar,
  currentCompany: state.currentCompany,
});

const mapDispatchToProps = {
  getMenuByUID,
  setShippingPrice,
  getCurrentCompany,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductSelectorScreen));
