export const RESTAURANTS_LIST      = '/';
export const PRODUCTS_SELECTOR     = '/:companyAlias';
export const SHOPPING_CAR          = '/shopping-car/:companyAlias';
export const NEW_CUSTOM_PRODUCT    = '/new-custom-product/:companyAlias';
export const EDIT_CUSTOM_PRODUCT   = '/edit-custom-product/:companyAlias/:productID';
export const FAQ                   = '/frequent-questions/:companyAlias';
export const CONTACT_PAGE          = '/contact-page/:companyAlias';
export const BACK_ESTABLECIMIENTOS = 'http://establecimientos.alacuenta.com/';

export const Routes = {
  getRestaurantList:   () => RESTAURANTS_LIST,
  getProductsSelector: (companyAlias) => PRODUCTS_SELECTOR.replace(':companyAlias', companyAlias),
  getShoppingCar:      (companyAlias) => SHOPPING_CAR.replace(':companyAlias', companyAlias),
  getNewProduct:       (companyAlias) => NEW_CUSTOM_PRODUCT.replace(':companyAlias', companyAlias),
  getEditProduct:      (companyAlias, productID) => EDIT_CUSTOM_PRODUCT.replace(':companyAlias', companyAlias).replace(':productID', productID),
  getFAQ:              (companyAlias) => FAQ.replace(':companyAlias', companyAlias),
  getContactPage:      (companyAlias) => CONTACT_PAGE.replace(':companyAlias', companyAlias),
};