import { type as addProductType } from '../actions/addProduct';
import { type as removeProductType } from '../actions/removeProduct';
import { type as deleteProductType } from '../actions/deleteProduct';
import { type as setSavedOrderType } from '../actions/setSavedOrder';

const defaultState = {
  totalProducts: 0,
  totalBuy: 0,
  elementsSelected: []
};

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case setSavedOrderType: {
      return payload;
    }
    case addProductType: {
      const existingSelections = state.elementsSelected.filter(p => p.product.id === payload.product.id);
      //const shippingPrice = parseInt(payload.currentCompany.locationInfo.shippingPrice);
      if(existingSelections.length === 0) {
        const newSelection = {
          product: payload.product,
          quantity: 1,
          sumPrice: parseInt(payload.product.price)
        };

        const elementsSelected = [...state.elementsSelected, newSelection];
        return {
          totalQuantity: elementsSelected.reduce((totalCount, element) => totalCount + element.quantity, 0),
          totalPriceProducts: elementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), 0),
          //totalBuy:           elementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), shippingPrice),
          elementsSelected: elementsSelected
        };
      }
      else if(existingSelections.length === 1){
        const currentSelection = existingSelections[0];
        currentSelection.quantity += 1;
        currentSelection.sumPrice = currentSelection.product.price * currentSelection.quantity;
        
        const elementsSelected = [...state.elementsSelected];
        return {
          totalQuantity: elementsSelected.reduce((totalCount, element) => totalCount + element.quantity, 0),
          totalPriceProducts: elementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), 0),
          //totalBuy:           elementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), shippingPrice),
          elementsSelected: elementsSelected
        };
      }
      break;
    }
    case removeProductType: {
      const existingSelections = state.elementsSelected.filter(p => p.product.id === payload.product.id);
      //const shippingPrice = parseInt(payload.currentCompany.locationInfo.shippingPrice);
      if(existingSelections.length === 1){
        const currentSelection = existingSelections[0];
        currentSelection.quantity -= 1;
        currentSelection.sumPrice = currentSelection.product.price * currentSelection.quantity;
        if(currentSelection.quantity === 0)
          state.elementsSelected = state.elementsSelected.filter(p => p.product.name !== payload.product.name);
      }

      const elementsSelected = [...state.elementsSelected];
      return {
        totalQuantity: elementsSelected.reduce((totalCount, element) => totalCount + element.quantity, 0),
        totalPriceProducts: elementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), 0),
        //totalBuy:           elementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), shippingPrice),
        elementsSelected: elementsSelected
      };
    }

    case deleteProductType: {
      const newElementsSelected = state.elementsSelected.filter(p => p.product.name !== payload.product.name);
      //const shippingPrice = parseInt(payload.currentCompany.locationInfo.shippingPrice);
      return {
        totalQuantity: newElementsSelected.reduce((totalCount, element) => totalCount + element.quantity, 0),
        totalPriceProducts: newElementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), 0),
        //totalBuy:           newElementsSelected.reduce((totalValue, element) => totalValue + (element.sumPrice), shippingPrice),
        elementsSelected: newElementsSelected
      };
    }
    default:
      return state;
  }
}

export default reducer;